import React, { useState } from "react";
import { Form } from "@unform/web";

import Cards from 'react-credit-cards'

import InputMask from "../../components/InputMask";

// Css
import "./Payments.css";
import 'react-credit-cards/es/styles-compiled.css';

// Assets
import cartao from "../../assets/orderContact/cartao.svg";
import excluir from "../../assets/payments/excluir.svg";
import principal from "../../assets/payments/principal.svg";

// Local components
import NavBar from "../../components/NavBar";
import ButtonPadrao from "../../components/ButtonPadrao";
import Switch from "../../components/Switch";
import ButtonVoltar from "../../components/ButtonVoltar";
import links from "../../config/Links";

const initialList = [
  {
    id: "1",
    number: "5454",
  },
  {
    id: "2",
    number: "1239",
  },
  {
    id: "3",
    number: "5687",
  },
];

export default function Payments() {
  const [salvar, setSalvar] = useState(false);
  const [list, setList] = useState(initialList);

  // credit card states utils

  const [cvc, setCvc] = useState('');
  const [expiry, setExpiry] = useState('');
  const [focus, setFocus] = useState('');
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');

  function handleRemove(id) {
    const newList = list.filter((item) => item.id !== id);

    setList(newList);
  }

  return (
    <div className="container-principal-payments">
      <NavBar />
      <ButtonVoltar to={links.main} />

      <div className="container">
        <div className="margin-payments">
          <div className="container-central-payments">
            <div className="container-payments-left">
              <h1>Pagamentos</h1>
              <h3>Meios de pagamentos cadastrados</h3>

              <ul style={{ listStyle: "none" }}>
                {list.map((item) => (
                  <li key={item.id}>
                    <div className="payments">
                      <div className="input-cartao-payments">
                        <div style={{ margin: "-9px" }}>
                          <div className="min-cartao-payments">
                            <div className="min-centro-cartao-payments"></div>
                            {item.id === "1" ? (
                              <img
                                alt="principal"
                                style={{
                                  position: "relative",
                                  left: "-28px",
                                  top: "-7px",
                                }}
                                src={principal}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div>
                          <p>....</p>
                        </div>
                        <div>
                          <input value={item.number} readOnly />
                        </div>
                      </div>
                      <div className="img-payments">
                        <img
                          alt="excluir"
                          onClick={() => handleRemove(item.id)}
                          src={excluir}
                        />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="divisao-payments"></div>
            <div className="container-payments-right">
              <div>
                <h1>Adicionar meio de pagamento</h1>
                <h3>Insira as informações do cartão</h3>
              </div>

              <div style={{ margin: "20px" }}>
                <Cards
                  cvc={cvc}
                  expiry={expiry}
                  focused={focus}
                  name={name}
                  number={number}
                  locale={{ valid: "Válido até" }}
                  placeholders={
                    { name: "Nome do titular" }
                  }
                />
              </div>
              <Form style={{ width: "400px" }}>
                <div className="form-payments">
                <InputMask 
                  style={{ height: "50px", textAlign:"center"}}
                  name="number"
                  type="number"
                  placeholder="Número do cartão"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  onFocus={(e) => setFocus(e.target.name)}
                  maskChar=" "
                  mask="9999 9999 9999 9999"
                />

                <InputMask
                  style={{ height: "50px" }}
                  name="name"
                  type="text"
                  value={name}
                  placeholder="Nome do titular"
                  onChange={(e) => setName(e.target.value)}
                  onFocus={(e) => setFocus(e.target.name)}
                />
                </div>

                <div className="input-date-cod-payments">
                  <InputMask
                      style={{
                        width: "180px",
                        height: "50px",
                        textAlign: "center"
                      }}
                      name="expiry"
                      type="text"
                      value={expiry}
                      placeholder="00/0000"
                      mask="99/9999"
                      maskChar="_"
                      onChange={(e) => setExpiry(e.target.value)}
                      onFocus={(e) => setFocus(e.target.name)}
                    />
                
                 <input
                      className="input-date-cod-payments-last-input"
                      style={{ 
                        width: "180px",
                         height: "50px",
                          textAlign: "center"
                         }}
                      name="cvc"
                      type="number"
                      placeholder="Cód. Segurança"
                      value={cvc}
                      onChange={(e) => setCvc(e.target.value)}
                      onFocus={(e) => setFocus(e.target.name)}
                      maxLength="3"
                    />
                </div>

                <div className="switch-payments">
                  <Switch
                    selected={salvar}
                    toggleSelected={() => {
                      setSalvar(!salvar);
                    }}
                  />
                  <h5> Marcar como meio de pagamento principal</h5>
                </div>
              </Form>
              <div className="btn-finalizar-payments">
                <ButtonPadrao img={cartao}>
                  Adicionar
                </ButtonPadrao>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
