
import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import {validatePhoneInput } from "../../Helper/Utils";
import "./styles.css";

export default function InputMaskPhone({ defaultValueProp, customClassName, placeholder, value, name, id, style}, ...rest) {

  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <div className={`inputMaskPhone ${customClassName}`}>
      {error && <span className="inputError">{error}</span>}
      <input
        ref={inputRef}
        id={id}
        name={name}
        type='tel'
        maxlength="15"
        style={style ? style : {}}
        onKeyUp={validatePhoneInput}
        placeholder={placeholder}
        // onChange={onChange}
        defaultValue={defaultValueProp ? defaultValueProp : defaultValue }
        {...rest} />
    </div>
  )
}