import React, { useState } from "react";
import "./InputAddProduct.css";

// module internal imports
// import { ToastContainer } from "./Toasters";
import { useHistory } from "react-router-dom";
import Product from "../Api/Product";
import iconLoadBlack from "../assets/Global/icon-loading-black.svg";

import links from "../config/Links";

function InputAddProduct(props) {
  // internal module states
  const [items, setItems] = useState([]);
  const [isSearching, setIsSearching] = useState(false)
  const [text, setText] = useState("")
  const [suggestions, setSuggestions] = useState([])

  // API modules
  const product = new Product();

  // pagination hook
  const history = useHistory();

  //saving into storage to be used in the next page
  function handleOnSelectItem(item) {
    localStorage.setItem(`lastItem`, JSON.stringify(item));
    history.push(links.productsAdd);
  }

  // API call
  async function _handleGetProducts(text) {
    if (text.length < 3) return
      setIsSearching(true);

      // Find order type saved in storage
      const deliveryType = parseInt( localStorage.getItem("deliveryType") || 1 );

      // API call
      product.exec("POST", "product/search/", {
        name    : text,
        asin    : '',
        keyword : '',
        brand   : '',
        /* Ignore mooving products/packages if delivery type = 1 */
        exceptCategoryId : ( deliveryType === 1 ? "15,16" : "" )
      }).then((resp) => {
        setItems(resp.rows);
        setSuggestions(resp.rows)

        // console.log(resp.rows)
        setIsSearching(false)
        return resp.rows
      });
    
  }
  
  const onChangeHandler = async (text) => {
    setText(text)

    if (text.length === 0) {
      setText("")
      setSuggestions([])
      return
    }
    _handleGetProducts(text);
  }

  return (
    <>
      {/* <ToastContainer /> */}
      <div className="pesquisar-produtos">
        {isSearching &&
          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <img className="loader" src={iconLoadBlack} alt="Carregando ..." />
          </div>}
        <div className="pesquisar-produtos-autocomplete" >
          <input
            onChange={(e) => { onChangeHandler(e.target.value) }}
            // onKeyPress={ handleOnTextDelete}
            value={text}
            placeholder="Digite o nome do item"

          />
          {suggestions.length> 0   && text !== "" ?
            (<div className={"pesquisar-produtos-autocomplete-container"}>
              {suggestions.map((item, index) =>
                <div
                 className="pesquisar-produtos-autocomplete-item" 
                 id={index}
                 key={index}
                 onClick={() => {handleOnSelectItem(item)}}
                 >
                  <h2>{item.name}</h2>
                </div>
              )}
            </div>) : <></>
          }

        </div>
      </div>
    </>
  );
}
export default InputAddProduct;
