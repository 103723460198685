// Components
import { useRef } from "react";
import Auth from "../../Api/Auth";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useAlert } from "react-alert";
import { errorToaster, successToaster, ToastContainer } from "../../components/Toasters";

// Data analysis
import { MP } from "../../components/MixPanel";

// Local components
import { useLoading } from "../../components/loading";
import links from "../../config/Links";
import ButtonAvancar from "../../components/ButtonAvancar";
import InputForm from "../../components/InputForm";
import ButtonVoltar from "../../components/ButtonVoltar";

// CSS
import "./index.css";

// Images
import pageImage from "../../assets/lostPass/senha-recuperacao.svg";

//
function RecoveryPass(props) {

  // MP
  MP("Recovery Pass");

  // Load control
  const formRef = useRef(null);
  
  // Alert
  const alert = useAlert();

  // Loading
  const { setLoading } = useLoading();

  async function updateUser(data) {
    
    // Loader
    setLoading(true);
    
    // Auth API
    const auth = new Auth();

    // Exec request
    await auth.updatePass(data)
      .then((ret) => {
        if ( ret && ret.success ) {
          // Success
          
          // MP
          MP("Recovery Pass", { "success": "true" });

          // alert.success(ret.message_br, { title: "Alteração de senha", closeCopy: "Ok" });
          successToaster(ret.message_br);
          // Redir 
          props.history.push("/", { result: ret });
        } else {
          // Erro
          
          // MP
          MP("Recovery Pass", { "success": "false" });
                      
          // alert.info(ret.message_br, { title: "Alteração de senha", closeCopy: "Ok" });
          errorToaster(ret.message_br);
        }
      })
      .catch((error) => {
        
        // MP
        MP("Recovery Pass", { "error": "true" });
                
        if (error.response) {
          // Erro
          // console.log(error.response.data.message_br); // => the response payload 
          // alert.info(error.response.data.message_br, { title: "Alteração de senha", closeCopy: "Ok" });
          errorToaster(error.response.data.message_br);          
        } else {
          // Undefined error
          errorToaster("Ocorreu um erro não identificado. Tente novamente mais tarde.");  
        }
      });

    // Loader
    setLoading(false);
  }

  async function updateWorker(data) {
    
    // Loader
    setLoading(true);
    
    // Auth API
    const auth = new Auth();

    // Exec request
    await auth.updatePassWorker(data)
      .then((ret) => {
        if ( ret && ret.success ) {
          // Success
          alert.success("Sua senha foi alterada com sucesso. Acesse o App para realizar o login.", { title: "Alteração de senha", closeCopy: "Ok" });

          // Reset form
          formRef.current.reset();

          // successToaster(ret.message_br);
          // Redir 
          // props.history.push("/");
        } else {
            // Erro
            // alert.info(ret.message_br, { title: "Alteração de senha", closeCopy: "Ok" });
            errorToaster(ret.message_br);
        }
      })
      .catch((error) => {
        if (error.response) {
          // Erro
          // console.log(error.response.data.message_br); // => the response payload 
          alert.info(error.response.data.message_br, { title: "Alteração de senha", closeCopy: "Ok" });
          // errorToaster(error.response.data.message_br);          
        } else {
          // Undefined error
          alert.info("Ocorreu um erro não identificado. Tente novamente mais tarde.", { title: "Alteração de senha", closeCopy: "Ok" });
          // errorToaster("Ocorreu um erro não identificado. Tente novamente mais tarde.");  
        }
      });

    // Loader
    setLoading(false);
  }

  async function handleSubmit(data, { reset }) {

    // Get code
    const pathname = window.location.pathname;

    // Paths
    const pathUser    =   "/senha-recuperacao/";
    const pathWorker  =   "/senha-recuperacao-transportador/";

    // Identify if is user or worker by pathname
    const isUser      =   pathname.includes(pathUser);
    const isWorker    =   pathname.includes(pathWorker);

    // Replace pathname to get code
    const code        =   pathname.replace( ( isUser ? pathUser : pathWorker ),"");

    // Check data
    if ( !code ) {
      errorToaster("Ocorreu um erro no código de recuperação de senha. Código incorreto. Faça a solicitação novamente para alterar sua senha.");
      return;
    }

    // Check path
    if ( !isUser && !isWorker ) {
      errorToaster("Ocorreu um erro no código de recuperação de senha. URL incorreta. Faça a solicitação novamente para alterar sua senha.");
      return;
    }    

    try {
      // Remove all previous errors
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        pass: Yup.string().required("Digite sua nova senha."),
        cpass: Yup.string()
          .test("is-ok", "Senhas não conferem.", (value) => { return ( data.cpass === data.pass); })
          .required("Digite a confirmação da senha."),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      // Validation passed
      if( isUser){ 
        // Update user
        await updateUser({
          code : code,
          pass : data.pass,
        });
      } else if( isWorker ){
        // Update worker
        await updateWorker({
          code : code,
          pass : data.pass,
        });      
      }

      // Loading off
      setLoading(false);

    } catch (err) {
      /* const validationErrors = {}; */
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
      // Loader off
      setLoading(false);
    }
  }

  return (
    <div className="container-principal-PassRecovery">
      <div className="container-PassRecovery">
        <div className="container-PassRecovery">
         <ToastContainer />
         <ButtonVoltar to={links.home}/>
          <div className="container-form">            
            <div className="column-PassRecovery">
              <Form id="myform" ref={formRef} onSubmit={handleSubmit}>
                <Link to="/" className="bt-top-back"></Link>
                <h1>Alteração de senha</h1>
                <p className="container-description">
                  Digite a nova senha e a confirmação.
                </p>
                <div  className="inputField">
                  <InputForm name="pass" type="password" placeholder="Nova senha" />
                </div>
                <div  className="inputField">
                  <InputForm name="cpass" type="password" placeholder="Confirmação de senha" />
                </div>                
                </Form>
              </div>
              <div className="column detail-PassRecovery">
                <img src={pageImage} alt="Alteração de senha" />
              </div>
          </div>
        </div>
      </div>
      <div className="container-inferior-PassRecovery">
          <div className="container-AccountScreen">
            <form>
              <ButtonAvancar className={"container-AccountScreen-button"} Form="myform" type="submit">
                Confirmar
              </ButtonAvancar>
            </form>
          </div>
      </div>
    </div>
  );
}

export default withRouter(RecoveryPass);
