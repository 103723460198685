import Order from "../Api/Order";
import Auth from "../Api/Auth"

const DEBUG = false;
/**
 * Date Format : format dates to string in a specific format
 * @param {string} dte full | short date time
 * @param {number} dateStyle (optional) should be full | medium | long | short
 * @param {number} timeStyle (optional) should be full | medium | long | short
 * @param {string} locale (optional) the locale to use
 * @returns {string} date formated
 * @onerror {string} "" Return empty string and console.warn
 */

const order = new Order()

export function dateFormat(
  dte,
  dateStyle = "short",
  timeStyle = "short",
  locale = "pt-br"
) {
  // Date variable to return
  try {
    // Options
    const options = {
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      year: "numeric",
      dateStyle: dateStyle || "short",
      timeStyle: timeStyle || "short",
    };

    // Apply locale and options
    const datef = new Date(Date.parse(dte));
    const dt = datef.toLocaleDateString(locale, options).toString();

    // Force BR format
    const arrBR = dt.split("/");
    const dtBR = arrBR[1] + "/" + arrBR[0] + "/" + arrBR[2];

    // Time
    const tm = datef
      .toLocaleTimeString(locale, options)
      .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
      .toString();

    // Format
    let ret = dtBR + " " + tm;

    // Check optionals
    if (timeStyle === "none") ret = dtBR;
    if (dateStyle === "none") ret = tm;

    // Return
    return ret;
  } catch (error) {
    // Error
    console.warn("dateFormat : Error passing parameter date : " + dte, error);
    return "";
  }
}

/**
 * Shopcart Check : check if the shopcart is not empty and all steps are completed
 * @returns {boolean} true | false
 * @onerror {boolean} false
 */
export function shopcartCheck() {
  // Check shopcart parameters
  try {
    // Check parameters
    const deliveryDate    =   ( localStorage.getItem("dte")          ? true : false  );
    const deliveryFrom    =   ( localStorage.getItem("addressFrom")  ? true : false  );
    const deliveryTo      =   ( localStorage.getItem("addressTo")    ? true : false  );
    let latAndlongTo      =   JSON.parse( localStorage.getItem("latAndlongTo")       ) || false;
    let latAndlongFrom    =   JSON.parse( localStorage.getItem("latAndlongFrom")     ) || false;

    if ( 
      !latAndlongTo?.lat                 || 
      !latAndlongTo?.lng                || 
      latAndlongTo?.lat === 0            || 
      latAndlongTo?.lng === 0            || 
      latAndlongTo?.lat === undefined    || 
      latAndlongTo?.lng === undefined    || 
      latAndlongTo?.lat === null         || 
      latAndlongTo?.lng === null 
    ) {
      latAndlongTo = false;
    } else {
      latAndlongTo = true;
    }
    if (  
      !latAndlongFrom?.lat               || 
      !latAndlongFrom?.lng               || 
      latAndlongFrom?.lat === 0          || 
      latAndlongFrom?.lng === 0          || 
      latAndlongFrom?.lat === undefined  || 
      latAndlongFrom?.lng === undefined  || 
      latAndlongFrom?.lat === null       || 
      latAndlongFrom?.lng === null  
    ) {
      latAndlongFrom = false;
    } else{
      latAndlongFrom = true;
    }

    // Check contact data
    // const contactDataTo = (localStorage.getItem("contactDataTo") ? true : false)
    // const contactDataFrom = (localStorage.getItem("contactDataFrom") ? true : false)

    // Check products
    const deliveryProducts        =   JSON.parse(localStorage.getItem("items"));
    const deliveryProductsTotal   =   deliveryProducts.length;

    // Check Contact data
    // const deliveryProductsTotal = deliveryProducts.length;
    // console.log("shopcartCheck validation teste", deliveryDate, deliveryFrom, deliveryTo, latAndlongTo, latAndlongFrom, deliveryProductsTotal);
    
    // Validation
    const validation = Boolean(
      deliveryDate            &&
      deliveryFrom            &&
      deliveryTo              &&
      deliveryProductsTotal   &&
      latAndlongTo            &&
      latAndlongFrom  
    );

    // Return
    return validation;

  } catch (e) {
    // Error
    console.warn("shopcartCheck : Error validation shopcart", e);
    return false;
  }
}


/**
 * Get the value for a given key in address_components
 * 
 * @param {Array} components address_components returned from Google maps autocomplete
 * @param type key for desired address component
 * @returns {String} value, if found, for given type (key)
 */
export function extractAddress(components, type) {
  return components.filter((component) => component.types.indexOf(type) === 0).map((item) => item.long_name).pop() || "";
}

/**
 * Payload Mount: Mounts a payload object once it pass on validation shopcart test
 * @returns {object} payload object to generate an order
 * @onerror {boolean} false
 */
export async function mountPayload(payment) {
  try {
    let to = (JSON.parse(localStorage.getItem("contactDataTo")));
    let from = (JSON.parse(localStorage.getItem("contactDataFrom")));
    let latAndlongFrom = (JSON.parse(localStorage.getItem("latAndlongFrom")))
    let latAndlongTo = (JSON.parse(localStorage.getItem("latAndlongTo")))
    let addressFromComplete = (JSON.parse(localStorage.getItem("completeFrom")))
    let addressToComplete = (JSON.parse(localStorage.getItem("completeTo")))

    // Stairs info
    let stairsTo = (JSON.parse(localStorage.getItem("completeTo")).stairs)
    let stairsFrom = (JSON.parse(localStorage.getItem("completeFrom")).stairs)

    // debug payload module
    if(DEBUG) {
      console.log('addressFromComplete >>>', addressFromComplete)
      console.log('addressToComplete >>>', addressToComplete)
      // console.log('RESULT FROM >>>', extractAddress(addressFromComplete, 'postal_code'))
    }

    let products = JSON.parse(localStorage.getItem("items"));
    let payload = {}

    let orderProduct = []

    products.map(({ id, quantity, weight, width, height, lenght, depth }) => {

      orderProduct.push({
        "id_product": (id),
        "quantity": (quantity),
        "weight": (weight),
        "width": (width),
        "height": (height),
        "length": (depth)
      })
    })

        //MODEL TYPES
    // {street: "",
    // number: "",
    // city: "",
    // hood: "",
    // country: "",
    // district: "",
    // complement: ""}


    // Getting info for estimed-price payload 

    const auth = new Auth()
    let contactDefault = await auth.me()

    // default payload
    payload = {
      type: JSON.parse(localStorage.getItem("deliveryType")) === null ? false : JSON.parse(localStorage.getItem("deliveryType")),
      dte_delivery: new Date((localStorage.getItem("dte"))).toISOString().slice(0, 19).replace('T', ' '),
      dedicated: JSON.parse(localStorage.getItem("dedicatedDelivery")) === null ? false : JSON.parse(localStorage.getItem("dedicatedDelivery")),
      local_from_name: from?.name || contactDefault?.name,
      local_from_email: from?.email || contactDefault?.email,
      local_from_cpf: from?.cpf || contactDefault?.cpf,
      local_from_cellphone: from?.cellphone || contactDefault?.cellphone,
      local_from_cep: addressFromComplete?.postal_code,
      local_from_lat: latAndlongFrom?.lat,
      local_from_lon: latAndlongFrom?.lng,
      local_from_address: addressFromComplete?.street.trim(),
      local_from_number: addressFromComplete?.number.trim(),
      local_from_district: addressFromComplete?.hood.trim(),
      local_from_city: addressFromComplete?.city.trim(),
      local_from_state: addressFromComplete?.district.trim(),
      local_from_country: addressFromComplete?.country.toString().trim(),
      local_from_complement: addressFromComplete?.complement.trim(),
      local_from_floor: addressFromComplete?.floor === "" ? "" : parseInt(addressFromComplete?.floor),
      local_to_name: to?.name || contactDefault?.name,
      local_to_email: to?.email || contactDefault?.email,
      local_to_cpf: to?.cpf || contactDefault?.cpf,
      local_to_cellphone: to?.cellphone || contactDefault?.cellphone,
      local_to_cep:  addressToComplete?.postal_code,
      local_to_lat: latAndlongTo?.lat,
      local_to_lon: latAndlongTo?.lng,
      local_to_address: addressToComplete?.street.trim(),
      local_to_number: addressToComplete?.number.trim(),
      local_to_district:  addressToComplete?.hood.trim(),
      local_to_city: addressToComplete?.city.trim(),
      local_to_state: addressToComplete?.district.trim(),
      local_to_country:  addressToComplete?.country.toString().trim(),
      local_to_complement:  addressToComplete?.complement.trim(),
      local_to_floor: addressToComplete?.floor === "" ? "" : parseInt(addressToComplete?.floor),
      local_to_elevator      :    stairsTo === "2"      ? 1: 0,
      local_from_elevator    :    stairsFrom === "2"    ? 1: 0,
      local_to_entrance      :    stairsTo === "3"      ? 1: 0,
      local_from_entrance    :    stairsFrom === "3"    ? 1: 0,
      local_to_stairs        :    stairsTo === "1"      ? 1: 0,
      local_from_stairs      :    stairsFrom === "1"    ? 1: 0,
      optionals: [

      ],
      //products payload
      products: orderProduct,

      //payment payload comming from contact page via props
      payment: payment
    }
    if(DEBUG) console.log("payload create order:", payload)
    return payload
  } catch (err) {
    console.log("mountPayload error:", err)
  }
}

/**
 * This function estimates price receiving a simple payload for it 
 * @returns {estimated-price-state} 
 * @onerror {boolean} false
 */


// Order PRICE ESTIMATION Util
export async function orderEstimatePrice(setApiResponse, setLoading, errorToaster ) {

  const DEBUG = false;

  let latAndlongFrom = (JSON.parse(localStorage.getItem("latAndlongFrom")))
  let latAndlongTo = (JSON.parse(localStorage.getItem("latAndlongTo")))

  if(DEBUG) console.log("orderEstimatePrice >>", new Date(), "to:", latAndlongTo)

  let products = JSON.parse(localStorage.getItem("items"));

  if(DEBUG) console.log("orderEstimatePrice >>", new Date(), "products:", products)
  let payload = {}

  // Products
  let orderProduct = []
  products.map(({ id, quantity, weight, width, height, depth }) => {

  orderProduct.push({
      "id_product"  :   id,
      "quantity"    :   quantity,
      "weight"      :   weight,
      "width"       :   width,
      "height"      :   height,
      "length"      :   depth
    })
  })

  // Floor
  const floorTo      =   JSON.parse(localStorage.getItem("completeTo"))?.floor    ||  0 ;
  const floorFrom    =   JSON.parse(localStorage.getItem("completeFrom"))?.floor  ||  0 ;

  // Stairs
  let stairsTo = (JSON.parse(localStorage.getItem("completeTo"))?.stairs)
  let stairsFrom = (JSON.parse(localStorage.getItem("completeFrom"))?.stairs)
  
  if(DEBUG) console.log("orderEstimatePrice >>", new Date(), "floorTo:", floorTo);
  if(DEBUG) console.log("orderEstimatePrice >>", new Date(), "floorFrom:", floorFrom);

  // Final payload
  payload = {
    type                   :    JSON.parse(localStorage.getItem("deliveryType"))         || false,
    dedicated              :    JSON.parse(localStorage.getItem("dedicatedDelivery"))    || false,
    dte_delivery           :    new Date((localStorage.getItem("dte"))).toISOString().slice(0, 19).replace('T', ' '),
    local_from_lat         :    latAndlongFrom?.lat,
    local_from_lon         :    latAndlongFrom?.lng,
    local_from_floor       :    floorFrom,
    local_to_lat           :    latAndlongTo?.lat,
    local_to_lon           :    latAndlongTo?.lng,
    local_to_floor         :    floorTo,
    optionals              :    [],
    products               :    orderProduct,
    local_to_elevator      :    stairsTo === "2"      ? 1: 0,
    local_from_elevator    :    stairsFrom === "2"    ? 1: 0,
    local_to_entrance      :    stairsTo === "3"      ? 1: 0,
    local_from_entrance    :    stairsFrom === "3"    ? 1: 0,
    local_to_stairs        :    stairsTo === "1"      ? 1: 0,
    local_from_stairs      :    stairsFrom === "1"    ? 1: 0,
  }

  if(DEBUG) console.log("orderEstimatePrice >>", new Date(), "payload", payload);

  // Getting the estimated price of an order
  order.orderPost("POST", "order/estimated-price", payload)
    .then((response) => {
      // if(DEBUG) console.log(response)
      // if(DEBUG) console.log(payload)
      if (response?.financial) {
        // Endpoint ok
        if (response?.financial?.error) {
          // Erro
          if(DEBUG) console.log("orderEstimatePrice >>", new Date(), "estimated-price error:", response);
        } else {
          // Success
          if(DEBUG) console.log("orderEstimatePrice >>", new Date(), "estimated-price ok:", response);
          setApiResponse(response);
        }

        setLoading(false);
        return response;
      }
    })
    .catch((err) => {
      // Error
      if(DEBUG) console.warn("orderEstimatePrice >>", new Date(), "Erro ao calcular preço estimado:", err);
      errorToaster("Erro ao calcular preço estimado.");
      setLoading(false);
      // Return
      return false;
    });
}

/**
 * Function to format date from ISO format to  
 * @returns {reponse-payload} 
 * @onerror {boolean} false
 */
export const handleNewDteFormat = (dteFormat) => {
  let dte = new Date(dteFormat);
  let dteFormated =
    dte.toLocaleDateString("pt-BR") + " às " + dte.toTimeString("pt-BR");
  return dteFormated.substring(0, 19) + "hs";
};

/**
 * Function to deal with the credit card data expiry format -> XX/XXXX
 * @returns {boolean} 
 * @onerror {boolean} false
 */
export function isExpiryDateValid(date ) {
  
  let firstTest = date.indexOf("/");
  if (firstTest === -1) return false;

  let dateValidation = date.split("/");

  // Fix year if length is only 2. If less than 2 or 3 it's invalid
  if( dateValidation[1].length === 2 )
    dateValidation[1] = "20" + dateValidation[1];

  if (
    // Validate month
    ( dateValidation[0].length === 2 && dateValidation[0] >= 1 && dateValidation[0] <= 12 )
    &&
    // Validate year
    ( dateValidation[1] >= new Date().getFullYear() )
    ) {
    return true;
  } else {
    return false;
  }
}

/**
 * Function to keep always the "/" inside the date format -> XX/XXXX
 * @returns {boolean} 
 * @onerror {boolean} false
 */
export const handleValidateExpiryInput = (event, setExpiry) => {
  let control = true
  let validation = event.target.value.replace(
    /[^0-9]{7}/g, '' // To allow only numbers
    ).replace(
        /^([2-9])$/g, '0$1' // To handle 3 > 03
    ).replace(
        /^(1{1})([3-9]{1})$/g, '0$1/$2' // 13 > 01/3
    ).replace(
        /^0{1,}/g, '0' // To handle 00 > 0
    ).replace(
        /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2' // To handle 113 > 11/3
    );

  // antigo regex, nao permite deletar o /
  // /^(0[1-9]|1[0-2])$/g, '$1/' // 11 > 11/
  // ).replace(
  //   /^([0-1])([3-9])$/g, '0$1/$2' // 13 > 01/3
  // ).replace(
  //   /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2' // 141 > 01/41
  // ).replace(
  //   /^([0]+)|[0]+$/g, '0' // 0/ > 0 and 00 > 0
  // ).replace(
  //   /[^\d/]|^[/]*$/g, '' // To allow only digits and /
  // ).replace(
  //   ////g, '/' // Prevent entering more than 1 /
  // );

  setExpiry(validation)
}

export const preventLetterInput  = (event) => {
  if (!/[0-9]|[.]|[,]/.test(event.key)) {
    event.preventDefault();
  }
}

export function removeStorage(name) {
  localStorage.removeItem(`${name}`)
}

export function shopcartClean() {
  localStorage.removeItem("deliveryType");
  localStorage.removeItem("dte");
  localStorage.removeItem("lastItem");
  localStorage.removeItem("latAndlongFrom");
  localStorage.removeItem("latAndlongTo");
  localStorage.removeItem("deliveryType");
  localStorage.removeItem("addressFrom");
  localStorage.removeItem("addressTo");
  localStorage.removeItem("mapReadyFrom");
  localStorage.removeItem("mapReadyTo");
  localStorage.removeItem("items");
  localStorage.removeItem("lastItem");
  localStorage.removeItem("completeFrom");
  localStorage.removeItem("completeTo");
}

export function handleFinishMapTour() {
  // Tour controller
  if (localStorage.hasOwnProperty("pagesTourSeen")){
    const restProps = JSON.parse(localStorage.getItem('pagesTourSeen'))
    localStorage.setItem('pagesTourSeen', JSON.stringify({
      ...restProps,
      map: true,
    }))
  }else {
    localStorage.setItem('pagesTourSeen', JSON.stringify({
      map: true,
    }))
  }
}

/**
 * Function returns a native alert for better debugging on the go
 * @returns {alert} 
 * @onerror {boolean} false
 */
export function debugAlert( msg, user ){
  if( user.id === 1 || user.id === 19 )  {
    console.log("debugAlert >>", msg);
    // alert( msg );
  }
} 

export const validatePhoneInput = (event) => {
  let input = event.target
  input.value = phoneMask(input.value)
}

const phoneMask = (value) => {
  if (!value) return ""
  value = value.replace(/\D/g,'')
  value = value.replace(/(\d{2})(\d)/,"($1) $2")
  value = value.replace(/(\d)(\d{4})$/,"$1-$2")
  return value
}


export function initialValidatePhoneInput(value) {

  value = value.replace(/\D/g, '')
  value = value.replace(/(\d{2})/, '($1) ')
  const isMobilePhone = /^[(][0-9][0-9][)][\s][9]/.test(value)
  if (isMobilePhone) {
    
    // event.currentTarget.maxLength = 16
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{2})(\d{1})/, '($1) $2 ')
    value = value.replace(/(\d{4})/, '$1-')
    value = value.replace(/(\d{4})/, '$1')
  } else {
    // event.currentTarget.maxLength = 14
    value = value.replace(/(\d{4})/, '$1-')
    value = value.replace(/(\d{4})/, '$1')
  }
  return value
}
