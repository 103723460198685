/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

// Data analysis
import { MP } from "../../components/MixPanel";

// CSS
import "./SearchingDriver.css";

//ICONS
import checked from "../../assets/SearchingDriver/checked.svg";
import perfil from "../../assets/SearchingDriver/foto-perfil-jose.svg";
import cancelled from "../../assets/SearchingDriver/cancelled.svg";
import iconLoadBlack from "../../assets/Global/icon-loading-black.svg";
import compartilhar from "../../assets/SearchingDriver/icon-compartilhar-branco.svg";
import caminhao from "../../assets/SearchingDriver/caminhao.svg";

//COMPONENTS
import NavBar from "../../components/NavBar";
import ButtonPadrao from "../../components/ButtonPadrao";
import Modal from "../../components/Modal";
import Order from "../../Api/Order";
import { errorToaster, ToastContainer } from "../../components/Toasters";
import HelpButton from "../../components/ButtonHelp";

import links from "../../config/Links";
// Tour context 
import { ShepherdTourContext } from "react-shepherd";

export default function SearchingDriver( props ) {
  // Tour control
  const tour = useContext(ShepherdTourContext)

  const [isSearching, setIsSearching] = useState(true);
  const [isSchedule, setIsSchedule] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const [qrCodeSource, setQrCodeSource] = useState("");

  // Cancell requests
  function cancel(){
    setIsSearching(false);
    setIsCancelled(true);
    setHasError(true);
  }

  // Get order data
  const orderNumber = props?.orderId || JSON.parse(localStorage.getItem("lastestOrderNumber")) || 0;

  // Request to API and wait queue
  function orderWatch() {

    console.log("orderWatch called", new Date());

    // Stop if is cancelled
    if ( isCancelled || !isSearching || hasError ) return;

    if( hasError || isCancelled ) {
      // 
    
      // MP
      MP("Order Watch", { "success": "false", "data_error": "true" });
            
      errorToaster("Não foi possível carregar os dados de sua entrega. Entre em contato com nosso suporte.");
      cancel();
      return;
    }

    if( !orderNumber ) {
      // 

      // MP
      MP("Order Watch", { "success": "false", "order_id_error": "true" });

      errorToaster("Não foi possível carregar o código de seu pedido. Entre em contato com nosso suporte.");
      cancel();
      return;
    }

    console.log("orderWatch get Order", new Date());

    // Order
    const order = new Order(); 

    order.exec("GET", `order/${orderNumber}`, {}).then((response) => {
      // Get order data
        
      // MP
      MP("Order Watch", { "success": "true" });

      // Update response
      setApiResponse(response);

      // Update qrcode
      setQrCodeSource(response?.qrcode?.qrcode);      
            
      if ( response.order.status === 1) {
        setIsSearching(false);
        setIsSchedule(true);
        return;
      } else if ( response.order.status === 2) {
        setIsSearching(false);
        setIsCancelled(true);
        return;
      }
    })
    .catch(()   => {})
    .finally(() => {});
  }

  function handleFinishTor() {
    if (localStorage.hasOwnProperty("pagesTourSeen")){
      const restProps = JSON.parse(localStorage.getItem('pagesTourSeen'))
      localStorage.setItem('pagesTourSeen', JSON.stringify({
        ...restProps,
        searching_driver: true,
      }))
    }else {
      localStorage.setItem('pagesTourSeen', JSON.stringify({
        searching_driver: true,
      }))
    }
  }

  useEffect(() => {

      // Tour controller
      if (localStorage.hasOwnProperty("pagesTourSeen")){
        if(!JSON.parse(localStorage.getItem("pagesTourSeen")).searching_driver) {
          tour.start()
          handleFinishTor()
        }
      }else {
        tour.start()
        handleFinishTor()
      }

    if (localStorage.hasOwnProperty("items" || localStorage.hasOwnProperty("items" ))) {
      localStorage.setItem("items", JSON.stringify([]));
      localStorage.setItem("lastItem", JSON.stringify({}));
    }

    // Start
    orderWatch();

    // Watcher
    let timer1 = setInterval(() => orderWatch(), 10 * 1000);

    // Top page
    try { window.scrollTo(0, 0); } catch (err) {}
    
    return () => {
      clearInterval(timer1);
    };

  }, []);

  return (
    <div className="container-principal-SearchingDriver">
      <NavBar />
      <ToastContainer />
      <div className="container-central-SearchingDriver">
        
        {/* <div className="numero-pedido-SearchingDriver">
          { apiResponse.order?.id ? (
          <>
            <h2>Pedido n°</h2>
            <div className="numero-pedido-SearchingDrive-id">{apiResponse.order?.id}</div>
          </>
          ) : !isCancelled && (<b>Por favor aguade...</b>)}
        </div> */}

        <div
          className="visible-div"
          style={isSchedule ? { display: "none" } : {}}
        >
          {isSearching && !hasError && !isCancelled && (
            <>
              <div style={{ marginInline: "auto" }} className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className="conteudo-searchingDriver">
                <div style={{ display: "flex", flexDirection: "row-reverse", marginBottom: "8px" }}>
                  <img className="searchingDriver-loader" src={iconLoadBlack} alt=""/>
                </div>
                <h2>Buscando melhores transportadores</h2>
                <h3>Você será informado assim que seu pedido for aceito.</h3>
              </div>
            </>
          )}
          {(isCancelled && !hasError) && (
            <>
              <div className="checked-perfil-SearchingDriver">
                <img alt="checked" src={cancelled} />
              </div>
              <div
                style={{ marginBottom: "65px" }}
                className="conteudo-searchingDriver"
              >
                <h2>Solicitação cancelada</h2>
                <h3>Entre em contato com a nossa central de atendimento.</h3>
                <h3>Seu pagamento será automaticamente estornado.</h3>
              </div>
            </>
          )}
          {(hasError) && (
            <>
              <div className="checked-perfil-SearchingDriver">
                <img alt="checked" src={cancelled} />
              </div>
              <div
                style={{ marginBottom: "65px", textAlign: "center" }}
                className="conteudo-searchingDriver"
              >
                <h2>Ocorreu um erro em sua solicitação</h2>
                <h3>Entre em contato com a nossa central de atendimento<br/>para concluir sua solicitação de transporte.</h3>
              </div>
            </>
          )}          
        </div>

        {isSchedule && (
          <>
            <div className="checked-perfil-SearchingDriver">
              <img alt="checked" src={checked} />
              <div style={{ width: "80px" }}></div>
              {apiResponse?.worker &&
                <img className="perfilDriver-img" alt="perfil" src={apiResponse.worker.avatar_url || perfil} />
              }
            </div>
            <div
              style={{ marginBottom: "65px" }}
              className="conteudo-searchingDriver"
            >
              <h2>Transporte agendado</h2>
              {apiResponse?.worker &&
                <>
                  <h3>Transportador: {apiResponse.worker.name}</h3>
                  <h3>{apiResponse.worker.vehicle_brand} - {apiResponse.worker.vehicle_model} - {apiResponse.worker.vehicle_color} </h3>
                  <h3>Placa: {apiResponse.worker.vehicle_id}</h3>
                </>
              }
            </div>
          </>
        )}

        <div className="container-btn-searchingDriver">
          { qrCodeSource !== "" && (
          <div>
            <img style={{ height: "89px", width: "89" }} alt="qrcode" src={qrCodeSource} />
          </div>
          )}
          <h4>{apiResponse.order?.serial || ""}</h4>

          {(!isSearching && !isCancelled) && (
            <>
              <Link style={{ textDecoration: "none" }}
               to={{
                pathname: links.trackingmap,
                state: { item: {order:{ id: orderNumber}}}
              }}
              >

                <button
                  className="btn-acompanhar"
                >
                  Acompanhar
                  <img alt="caminhao" src={caminhao} />
                </button>
              </Link>
              <ButtonPadrao
                img={compartilhar}
                style={{
                  height: "53px",
                  alignItems: "center",
                  padding: "10px",
                }}
              // onClick={() => setModalOpen(true)}
              >
                Compartilhar
              </ButtonPadrao>
            </>
          )}

          {(isSearching && !isCancelled) && (
            <Link style={{ textDecoration: "none" }}
            to={{
              pathname: links.trackingmap,
              state: { item: {order:{ id: orderNumber}}}
            }}
            >

              <button
                className="btn-acompanhar"
              >
                Acompanhar
                <img alt="caminhao" src={caminhao} />
              </button>
            </Link>
          )}

          {(isCancelled) && (
            <div className="container-btn-searchingDriver">
              <HelpButton
                img={cancelled}
                style={{
                  height: "53px",
                  alignItems: "center",
                  padding: "10px",
                }}
                onClick={() => setModalOpen(true)}
              >
                Entre em contato
              </HelpButton>
            </div>
          )}
          {modalOpen && <Modal setOpenModal={setModalOpen} />}
        </div>
      </div>
    </div>
  );
}

// export default withRouter(SearchingDriver)