import chat from "../assets/Main/icon-chat.svg";

// CSS
import "./ButtonHelp.css";

// Call action
function handleSubmit() {
  return window["chatSupport"]();
}

export default function HelpButton( props ){
  return(
      <button className="btn-ajuda" onClick={ () => handleSubmit()  }>
        <img alt="chat" src={chat} />
        <h4>{ props.label || "Solicite ajuda agora"}</h4>
      </button>
  );
}