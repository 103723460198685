import { Link } from "react-router-dom";

// CSS
import "./ButtonVoltar.css";

// Assets
import iconVoltar from "../assets/Global/icon-voltar-topo.svg";

// Main function
export default function ButtonVoltar( props ){
    // Props
    const { to, className, onClick } = props;
        
    // If not TO, return empty
    if ( !to ) return <></>;

    // Class
    const classNameButton = `imgVoltar ${ className !== undefined ? className : "" }`;

    return (
        <div className={classNameButton}>
            <Link className="tour-main-return-button" to={to} onClick={onClick}>
                <img className={""} alt="Voltar" title="Voltar" src={iconVoltar} />
            </Link>       
        </div> 
    );
}