import { Component } from "react";
import "./ButtonApple.css";
import apple from "../../src/assets/home/apple.svg";

class ButtonApple extends Component {
  render() {
    const { props } = this;

    const defaultProps = {
      ...props,
      className: "btnApple btnHome " + (props.className ? props.className : ""),
    };

    return (
      <button {...defaultProps}>
        <div className="imgBtn">
          <img alt="" className="apple" src={apple} />
        </div>
        {props.children}
      </button>
    );
  }
}

export default ButtonApple;
