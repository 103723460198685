import { StrictMode, useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

// Data analysis
import { MP } from "../../components/MixPanel";

// Css
import "./TrackingMap.css";

// Assets
import truck from "../../assets/trackingMap/TruckDelivery.svg";

// Links
import links from "../../config/Links";

// Local components
import NavBar from "../../components/NavBar";
import Accordion from "../../components/Accordion";
import MapContainer from '../../components/MapContainer'
import ComponentTracking from "../../components/sliderTracking/ComponentTracking";
import ButtonVoltar from "../../components/ButtonVoltar";
import Order from "../../Api/Order";
import OrderStatus from "../../components/OrderStatus";
import { useLoading } from "../../components/loading";
import { errorToaster, ToastContainer } from "../../components/Toasters";
import { handleNewDteFormat, shopcartClean} from '../../Helper/Utils'

// Tour context import
import { ShepherdTourContext } from "react-shepherd";
import ModalBase from "../../components/ModalBase";

export default function TrackingMap(props) {

  // MP
  MP("Tracking Map");

  const tour = useContext(ShepherdTourContext)
  const DEBUG = false;
  const { id } = useParams()
  const location = useLocation();
  let item = (location.state?.item) || {};

  const history = useHistory()

  const [apiResponse, setApiResponse] = useState({ order: { id: "...", serial: "..." } })
  const [orderLogs, setOrderLogs] = useState(["Nenhuma alteração recente"])
  const [latFrom, setLatFrom] = useState(-21.17822)
  const [lgnFrom, setLgnFrom] = useState(-47.793505)
  const [lgnTo, setLgnTo] = useState(-21.171425)
  const [latTo, setLatTo] = useState(-47.808125)

  // Retry order states
  const [failedTwiceOnOrderLoad, setFailedTwiceOnOrderLoad] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)

  const { setLoading } = useLoading(true);

  function handleFinsishTour() {
     // Tour controller
     if (localStorage.hasOwnProperty("pagesTourSeen")){
      const restProps = JSON.parse(localStorage.getItem('pagesTourSeen'))
      localStorage.setItem('pagesTourSeen', JSON.stringify({
        ...restProps,
        tracking_map: true,
      }))
    }else {
      localStorage.setItem('pagesTourSeen', JSON.stringify({
        tracking_map: true,
      }))
    }
  }

  function isNumericValidator(value) {
    return /^-?\d+$/.test(value);
  }

  const handleRemakeShopCart = async (actionType) => {
    handleFinsishTour()
    // Adding loading
    setLoading(true)

    // Clears the shop cart
    shopcartClean()

    const apiUtil = new Order();
    let completeProductsArray = [];

      // console.log('Api order first call ', apiResponse)

      // Order data validation
      if (!apiResponse) {
        errorToaster("Erro ao carregar dados, por favor tente novamente mais tarde.")
        return
      }
      // Setting the mapReady prop to true
      localStorage.setItem("mapReadyFrom", JSON.stringify("true"));
      localStorage.setItem("mapReadyTo", JSON.stringify("true"));

      // Complete order destructuring
      const { order } = apiResponse

      // Processing origin and destiny coordinates,  delivery type and dedicated delivery
      const { local_to_lat, local_to_lon, local_from_lat, local_from_lon, type, dedicated } = order;

      localStorage.setItem('dedicatedDelivery', JSON.stringify(dedicated))
      localStorage.setItem("deliveryType", JSON.stringify(type));
      localStorage.setItem("latAndlongFrom", JSON.stringify({ lat: local_from_lat, lng: local_from_lon }));
      localStorage.setItem("latAndlongTo", JSON.stringify({ lat: local_to_lat, lng: local_to_lon }));

      // Processing order address data 
      const {
        // FROM
        local_from_cep,
        local_from_address,
        local_from_number,
        local_from_floor,
        local_from_complement,
        local_from_district,
        local_from_city,
        local_from_state,
        local_from_country,
        local_from_stairs,
        // TO
        local_to_cep,
        local_to_address,
        local_to_number,
        local_to_floor,
        local_to_complement,
        local_to_district,
        local_to_city,
        local_to_state,
        local_to_country,
        local_to_stairs, 
      } = order;

      const payloadAdressFrom = {
        street: local_from_address,
        number: local_from_number,
        city: local_from_city,
        hood: local_from_district,
        country: local_from_country,
        district: local_from_state,
        postal_code: local_from_cep,
        floor: local_from_floor,
        complement: local_from_complement,
        stairs: !local_from_stairs && local_from_floor === 0 ? 0 : 1
      }

      const payloadAdressTo = {
        street: local_to_address,
        number: local_to_number,
        city: local_to_city,
        hood: local_to_district,
        country: local_to_country,
        district: local_to_state,
        postal_code: local_to_cep,
        floor: local_to_floor,
        complement: local_to_complement,
        stairs: !local_to_stairs && local_to_floor === 0 ? 0 : 1
      }

      // DEBUG
      if (DEBUG) {
        console.log('Address response FROM ', payloadAdressFrom)
        console.log('Address response TO ', payloadAdressTo)
      }
      localStorage.setItem("completeFrom", JSON.stringify(payloadAdressFrom));
      localStorage.setItem("completeTo", JSON.stringify(payloadAdressTo));

      // Setting the order type based on previous order made
      localStorage.setItem("deliveryType", JSON.stringify(order.type));

      // Processing address display itens
      localStorage.setItem("addressFrom", JSON.stringify(`
        ${payloadAdressFrom.street},
        ${payloadAdressFrom.number}${payloadAdressFrom.complement ? (", " + payloadAdressFrom.complement) : ""}${payloadAdressFrom.floor !== 0 ? (", Andar " + payloadAdressFrom.floor) : ""},
        ${payloadAdressFrom.hood},
        ${payloadAdressFrom.city},
        ${payloadAdressFrom.district},
        ${payloadAdressFrom.country}  
      `));

      localStorage.setItem("addressTo", JSON.stringify(`
        ${payloadAdressTo.street},
        ${payloadAdressTo.number}${payloadAdressTo.complement ? (", " + payloadAdressTo.complement) : ""}${payloadAdressTo.floor !== 0 ? (", Andar " + payloadAdressTo.floor) : ""},
        ${payloadAdressTo.hood},
        ${payloadAdressTo.city},
        ${payloadAdressTo.district},
        ${payloadAdressTo.country}  
      `));

      // mapping and requesting the full products name
      const { products } = order
      products.map((item, index) => {
        //Requests and save all products data from API based on product ID
        try {
          apiUtil.exec("GET", `product/${item?.id_product}`, {})
          .then((responseItem) => {
            // pushes the complete item to the array
            completeProductsArray.push({...responseItem, quantity: item.quantity})
            localStorage.setItem("items", JSON.stringify(completeProductsArray));

          }).catch(err => {
            console.log(err)

          }).finally(() => {
            if (actionType == 'reorder') {
              // only redirects if the mapping is completed
              if(products.length === completeProductsArray.length) {
                setLoading(false)
                history.push(links.main)
              }
            } else if(actionType === 'repay') {
              // Repay flow:
              // Setting order id into storage
              localStorage.setItem("lastestOrderNumber", JSON.stringify(apiResponse?.order?.id));
              localStorage.setItem("pricef", JSON.stringify(apiResponse?.financial?.pricef));
              // localStorage.setItem("repay", JSON.stringify(true));
              const orderDte = new Date(apiResponse.order.dte_delivery)
              // setting a suggested date 
              localStorage.setItem("dte", orderDte);

              // Pushing to checkout and sending params to repay
              history.push({
                pathname: links.orderContact,
                state: {
                  repay : true
                }    
              });    
            }

          })
        } catch (err) {
          setLoading(false)
          console.log(err)
        }
        // end of mapping
      })

      // returns to break the function
      return

  }

  const handleModalRedirect = () =>  {
    history.push({
      pathname: links.tracking,    
    });   
  }

  const _handleGetOrderDetail = async () => {
    const DEBUG = false;
    setLoading(true);

    // New order
    const order = new Order();
    if (id === undefined) {
      if (DEBUG) console.log("ENTERING FIRST CASE")
      // Start of ID indefined atempt
      try {
        if (DEBUG) console.log('ID >>', item.order.id)
        order.exec("GET", `order/${item?.order?.id}`, {})
          .then((responseOrder) => {
            // Resolving order worker model
            if (isNumericValidator(responseOrder?.worker?.vehicle_model)) {
              order.exec("GET", `worker/${responseOrder.worker.id}`)
                .then((response) => {
                  const vehicleType = response?.vehicle_type
                  order.exec("GET", `vehicle/brands/${response?.vehicle_type}`)
                    .then((response) => {
                      if (DEBUG) console.log(response)
                      let extractedBrandId
                      try {
                        extractedBrandId = response.find(x => x?.name === responseOrder?.worker?.vehicle_brand)?.id || "...";

                      }catch(err) {
                          // log it but it doesnt break it
                          if(DEBUG)console.log(err)
                        
                      }
                      order.exec("GET", `vehicle/models/${vehicleType}/${extractedBrandId}`)
                        .then((responseFinal) => {
                          if (DEBUG) console.log('Final', responseFinal)
                          const model = parseInt(responseOrder.worker.vehicle_model)
                          if (DEBUG) console.log('model em numero', model)
                          if (DEBUG) console.log(extractedModel)
                          let extractedModel
                          try {
                            extractedModel = responseFinal.find(x => x?.id === model) || "...";
    
                          }catch(err) {
                              // log it but it doesnt break it
                              if (DEBUG) console.log(err)                            
                          }
                          setApiResponse({ ...responseOrder, worker: { ...responseOrder?.worker, vehicle_model: extractedModel?.name || "..." } })
                        }).finally(
                          () => setLoading(false)
                        )
                    })
                }).catch((err) => {
                  console.log(err)
                  setShowErrorModal(true)
                  setLoading(false)
                })
            } else {
              setApiResponse(responseOrder)
              setLoading(false);
            }
            if (responseOrder) {
              if (DEBUG) console.log('RESPONSE FIRST FUNC', responseOrder)
              if (responseOrder.order?.logs) {
                let array = item.order?.logs || responseOrder?.order?.logs
                array.reverse()
                setOrderLogs(array)
              }
            }
          });
      } catch (err) {
        setShowErrorModal(true)
        if (DEBUG)  console.log('ERROR ON ORDER LOAD TYPE 1>>>', err)
      }
    } else {
      if (DEBUG) console.log("ENTERING SECOND CASE")
      try {
        item = ""
        order.exec("GET", `order/${id}`, {})
          .then((responseOrder) => {
            // counterOrderIdRequest++
            if (DEBUG) console.log('ID >>', id)
            item = responseOrder
            if (responseOrder) {
              // Resolving the worker model
              if (isNumericValidator(responseOrder?.worker?.vehicle_model)) {
                order.exec("GET", `worker/${responseOrder.worker.id}`)
                  .then((response) => {
                    const vehicleType = response?.vehicle_type
                    order.exec("GET", `vehicle/brands/${response?.vehicle_type}`)
                      .then((response) => {
                        let extractedBrandId
                        try {
                          extractedBrandId = response.find(x => x?.name === responseOrder?.worker?.vehicle_brand)?.id || "...";
  
                        }catch(err) {
                          // log it but it doesnt break it
                          if (DEBUG) console.log(err)   
                        }
                        order.exec("GET", `vehicle/models/${vehicleType}/${extractedBrandId}`)
                          .then((responseFinal) => {
                            const model = parseInt(responseOrder.worker.vehicle_model)
                            let extractedModel
                            try {
                              extractedModel = responseFinal.find(x => x.id === model) || "..."

                            } catch (err) {
                              // log it but it doesnt break it
                              if (DEBUG) console.log(err)
                            }
                            setApiResponse({ ...responseOrder, worker: { ...responseOrder?.worker, vehicle_model: extractedModel?.name || "..."} })
                          }).finally(
                            () => setLoading(false)
                          )
                      })
                  }).catch((err) => {
                    console.log(err)
                    // show modal error, only when order is completly broken
                    setShowErrorModal(true)
                    setLoading(false)

                  })
              } else {
                setApiResponse(responseOrder)
                setLoading(false);
              }
              if (responseOrder.order?.logs) {
                let array = responseOrder.order?.logs || responseOrder?.order?.logs
                array.reverse()
                setOrderLogs(array)
              }
              setLatFrom(responseOrder.order.local_from_lat)
              setLgnFrom(responseOrder.order.local_from_lon)
              setLatTo(responseOrder.order.local_to_lat)
              setLgnTo(responseOrder.order.local_to_lon)
            }
          })
      } catch (err) {
        setShowErrorModal(true)
        if (DEBUG)  console.log('ERROR ON ORDER LOAD TYPE 2>>>', err)
      }
      
    }
  }

  useEffect(() => {
     // Tour controller
     if (localStorage.hasOwnProperty("pagesTourSeen")){
      if(!JSON.parse(localStorage.getItem("pagesTourSeen")).tracking_map) {
        tour.start()
      }
    }else {
      tour.start()
    }
    _handleGetOrderDetail()

  }, [item])

  // Top page
  useEffect(() => {
    try { window.scrollTo(0, 0); } catch (err) {}
  }, []);  

  return (
    <div className="container-principal-trackingMap ">
      {/* {DEBUG && console.log(apiResponse)} */}
      <ToastContainer />
      {showErrorModal && <ModalBase
       customMessage={"Não foi possível trazer as informações do pedido, por favor tente novamente ou contate nosso suporte."} 
       confirmMessage={"Voltar para a página de pedidos"}
       setOpenModal={setShowErrorModal} 
       customFunction={handleModalRedirect} />}
      <NavBar />

      <MapContainer
        location=""
        origin={{ lat: apiResponse.order?.local_from_lat, lng: apiResponse.order?.local_from_lon }}
        destination={{ lat: apiResponse.order?.local_to_lat, lng: apiResponse.order?.local_to_lon }}
        style={{ height: "120vh" }} />
      <div className="container">
        <div className="itens-container-central-trackingMap">
          <ButtonVoltar onClick={handleFinsishTour} to={links.tracking} />
          <div className="infoTrackingMap">
            <div className="posicionamento-infoTracking tour-tracking-map-item">
              <div className="dadosTrackingMap">
                <h1>{apiResponse.order.serial}</h1>
                <h2>
                  {handleNewDteFormat(apiResponse.order.dte_delivery) || "..."}
                </h2>
                {apiResponse.financial && <div>Preço: {apiResponse.financial.pricef}</div>}
                {apiResponse.worker &&

                  <div>
                    <div>Transportador: {apiResponse.worker.name}</div>
                    <div>{apiResponse.worker.vehicle_brand} - {apiResponse.worker.vehicle_model} - {apiResponse.worker.vehicle_color}</div>
                    <div>Placa: {apiResponse.worker.vehicle_id}</div>
                  </div>
                }
              </div>
              <div className="perfilTrackingMap">
                <div className="coletaTrackingMap">
                  <OrderStatus status={apiResponse?.order?.status} order={apiResponse?.order} />
                </div>
                {
                  apiResponse.worker && apiResponse.worker.avatar_url &&
                  <div className="coletaTrackingMap-profile-wrapper" >
                    <img className="coletaTrackingMap-profile" alt="Transportador" src={apiResponse.worker.avatar_url} />
                  </div>
                }
                {/* <div
                  className="avaliacaoTrackingMap"
                  style={{ display: "flex" }}
                >
                  <div>
                    <img
                      alt="superStar"
                      style={{ marginTop: "7px" }}
                      src={superStar}
                    />
                  </div>

                  <div className="nota-trackingMap">
                    <div>
                      <img alt="estrela" src={estrela} />
                    </div>
                    <div>
                      <input value={apiResponse.worker.status} readOnly />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            {/* QR code section */}
            {apiResponse?.qrcode?.qrcode !== "" && apiResponse.order.payment_status === 1 && (apiResponse.order.status === 1 || apiResponse.order.status === 10) &&
              <div className="container-qr-code-tracking-map">
                <img alt="qrcode" src={apiResponse?.qrcode?.qrcode} />
              </div>
            }

            {/* QR code section */}
            <div className="trackingMap-actions tour-tracking-map-actions">
              {apiResponse.order.payment_status === 1 && <button className="bt" onClick={() => handleRemakeShopCart('reorder')} >Repetir pedido</button>}
              {apiResponse.order.payment_status !== 1 && <button className="bt" onClick={() => handleRemakeShopCart('repay')}>Realizar pagamento</button>}
            </div>
            <div className="btn-trackingMap tour-tracking-map-logs">
              <Accordion
                img={truck}
                style={{
                  height: "64px",
                  fontSize: "22px",
                }}
                content={
                  <StrictMode>
                    <ComponentTracking logs={orderLogs} />
                  </StrictMode>
                }
              >
                <p>Informações</p>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
