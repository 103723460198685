import React from "react";

// Routes
import Routes from "./routes";

// Global loader
import { LoadingProvider } from "./components/loading";

// Alerts
import Alert from "./components/Alert";

// CSS
import "./global.css";

function App() {
  return (
    <LoadingProvider>
      <Alert>
        <Routes />
      </Alert>
    </LoadingProvider>
  );
}

export default App;
