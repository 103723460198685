import {
  Route,
  Switch,
  Redirect,
  useLocation,
  BrowserRouter,
} from "react-router-dom";

// Pages
import Home from "./pages/Home";
import AccountScreen from "./pages/AccountScreen/AccountScreen";
import DeliveryProfile from "../src/pages/DeliveryProfile/DeliveryProfile";
import SearchingDriver from "../src/pages/SearchingDriver/SearchingDriver";
import Main from "../src/pages/Main/Main";
import ProductsAdd from "../src/pages/ProductsAdd/ProductsAdd";
import OrderResume from "./pages/OrderResume/OrderResume";
// import Chat from "../src/pages/Chat/Chat";
import OrderContact from "../src/pages/OrderContact/OrderContact";
import UserData from "./pages/UserData/UserData";
import LegalInfoPage from "../src/pages/LegalInfoPage/LegalInfoPage";
import Payments from "../src/pages/Payments/Payments";
import HelpPage from "../src/pages/HelpPage/HelpPage";
import Tracking from "../src/pages/Tracking/Tracking";
import LostPass from "../src/pages/LostPass/LostPass";
import PassRecovery from "../src/pages/PassRecovery/";
import TrackingMap from "../src/pages/TrackingMap/TrackingMap";
import FAQ from "../src/pages/FAQ/";
import Test from "../src/pages/Test/";
import NotFound from "../src/pages/404/";

import MapTo from "./pages/Map/MapTo";
import MapFrom from "./pages/Map/MapFrom";

// Tour imports section
import "./shepherd.css";
import { tourOptions } from "./components/Tour/utils/options"
import { ShepherdTour, } from "react-shepherd";

// Steps imports
import {deliveryProfile} from "./components/Tour/Steps/DeliveryProfile";
import { main } from "./components/Tour/Steps/Main";
import { orderResume } from "./components/Tour/Steps/OrderResume"
import { map } from "./components/Tour/Steps/Map"
import { productAdd } from "./components/Tour/Steps/ProductAdd";
import { userData } from "./components/Tour/Steps/UserData";
import { faq } from "./components/Tour/Steps/FAQ";
import { tracking } from "./components/Tour/Steps/Tracking";
import { searchingDriver } from "./components/Tour/Steps/SearchingDriver";
import { legalInfoPage } from "./components/Tour/Steps/LegaInfoPage";
import { trackingMap } from "./components/Tour/Steps/TrackingMap";
import { accountScreen } from "./components/Tour/Steps/AccountScreen";
import { lostPass } from "./components/Tour/Steps/LostPass";
import { orderContact } from "./components/Tour/Steps/OrderContact";

export default function Ruotes() {

  // Mounting and disposing all steps data
  const arraySteps = {
    'tipo-entrega':   deliveryProfile|| [],
    'principal': main || [],
    'resumo-pedido': orderResume || [],
    'onde-vem': map || [],
    'onde-vai': map || [],
    'adicionar-produto': productAdd || [],
    'dados-usuario': userData || [],
    'faq': faq || [],
    'Tracking': tracking || [],
    'encontre-motorista': searchingDriver || [],
    'LegalInfoPage': legalInfoPage || [],
    'pedido' : trackingMap || [],
    'senha-perdida':  lostPass || [],
    'criar-conta':  accountScreen || [],
    'pedido-pagamento' : orderContact || []
  }

  // Check if string is function
  function isFunction(functionToCheck) {
    return (
      functionToCheck &&
      {}.toString.call(functionToCheck) === "[object Function]"
    );
  }

  // Custom routing
  function CustomRoute({ isPrivate, ...rest }) {
    // Href routes
    const { pathname } = useLocation();

    // Route path
    const { path } = rest;

    // User token
    const token = localStorage.getItem("token");
    // TODO - Check if token is valid

    if (path === "*") {
      // URL path
      const finalPath = pathname.substring(1);
      console.log("route : ", finalPath);

      // TODO - Check if component exists
      if (isFunction(finalPath)) {
        // Set final route component
        rest.component = finalPath;
        rest.path = "/" + finalPath;
      } else {
        // Set 404 component
        rest.component = NotFound;
        rest.path = "/404";
      }

      // TODO: validate here which route we're to set the right steps js object
    }

    if (isPrivate && !token) {
      return <Redirect to="/" exact />;
    } else {
      const finalPath = pathname.substring(1);
      return (

        // shepperTour receives the path directly from route param
        <ShepherdTour steps={arraySteps[finalPath] || []} tourOptions={tourOptions || {}} >
          <Route {...rest} />
        </ShepherdTour>
      );
    }
  }

  return (
    <BrowserRouter>
      <Switch>
        <CustomRoute path="/" exact component={Home} />
        <CustomRoute path="/criar-conta" component={AccountScreen} />
        <CustomRoute path="/senha-perdida" component={LostPass} />
        <CustomRoute path="/senha-recuperacao" component={PassRecovery} />
        <CustomRoute path="/senha-recuperacao-transportador" component={PassRecovery} />

        {/* Privated routes */}
        <CustomRoute isPrivate path="/tipo-entrega" component={DeliveryProfile} />
        <CustomRoute isPrivate path="/principal" component={Main} />
        <CustomRoute isPrivate path="/onde-vem" component={MapFrom} />
        <CustomRoute isPrivate path="/onde-vai" component={MapTo} />
        <CustomRoute isPrivate path="/resumo-pedido" component={OrderResume} />
        <CustomRoute isPrivate path="/adicionar-produto" component={ProductsAdd} />
        <CustomRoute isPrivate path="/pedido-pagamento" component={OrderContact} />
        <CustomRoute isPrivate path="/encontre-motorista" component={SearchingDriver} />
        <CustomRoute isPrivate path="/dados-usuario" component={UserData} />
        <CustomRoute isPrivate path="/LegalInfoPage" component={LegalInfoPage} />
        <CustomRoute isPrivate path="/pagamento" component={Payments} />
        <CustomRoute isPrivate path="/ajuda" component={HelpPage} />
        <CustomRoute isPrivate path="/Tracking" component={Tracking} />
        <CustomRoute isPrivate path="/pedido" component={TrackingMap} />
        <CustomRoute isPrivate path="/pedido/:id" component={TrackingMap} />
        <CustomRoute isPrivate path="/faq" component={FAQ} />
        <CustomRoute isPrivate path="/test" component={Test} />

        {/* Catch all route */}
        <CustomRoute path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>

  );
}
