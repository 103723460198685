// Data analysis
import { MP } from "../../components/MixPanel";

// CSS 
import "./style.css";

export default function NotFound () {

    // MP
    MP("404"); 

    return(
            <div className="container-principal">
                <div className="margin-notfound">
                    <div className="container-central-notfound">
                    <h1>Página não encontrada</h1>
                    <p>
                        A página que você requisitou não foi localizada.
                        <br/>Utilize a navegação da plataforma.
                    </p>
                    </div>
                </div>
            </div>        
        );
}