import { Component } from "react";
import "./ButtonPadrao.css";

class ButtonAvancar extends Component {
  render() {
    const { props } = this;

    const defaultProps = {
      ...props,
      className: " btnPadrao " + (props.className ? props.className : ""),
    };

    return (
      <button {...defaultProps}>
        {props.children}
        <div className="imgBtnPadrao">
          <img alt="" src={props.img} />
        </div>
      </button>
    );
  }
}

export default ButtonAvancar;
