// Custom Alert
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertMUITemplate from "react-alert-template-mui";

// CSS
import "./style.css";

// Alert config ( default )
const alertOptions = {
  position: positions.MIDDLE,
  timeout: 0,
  offset: "30px",
  transition: transitions.SCALE,
};

// // Alert config ( custom )
// const AlertTemplate = ({ options, message, close }) => (
//     <div className={`alert ${options.type}`}>
//         {message}
//         <button onClick={close}>Ok</button>
//     </div>
// );

export default function Alert({ children }, props) {
  return (
    <AlertProvider template={AlertMUITemplate} {...alertOptions}>
      {children}
    </AlertProvider>
  );
}
