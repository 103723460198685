
// Style
import "./index.css";

export default function OrderStatus( props ){
  const DEBUG = false;
  /**Status */ 
  /*
  [0]     =   "Novo";
  [1]     =   "Confirmado"
  [10]    =   "Coleta realizada";
  [20]    =   "Entrega realizada";
  [30]    =   "Cancelado";
  [40]    =   "Adiado";
  [50]    =   "Finalizado";
  [100]   =   "( internal ) Estimativa de preço";
  */  

  const arrStatus  =   [];
  arrStatus[0]     =   "Novo";
  arrStatus[1]     =   "Confirmado";
  arrStatus[10]    =   "Coleta realizada";
  arrStatus[20]    =   "Entrega realizada";
  arrStatus[30]    =   "Cancelado";
  arrStatus[40]    =   "Adiado";
  arrStatus[50]    =   "Finalizado";
  arrStatus[100]   =   "Orçamento";
  arrStatus[1000]  =   "Indefinido"
  arrStatus[2001]  =   "Pagamento pendente";

  if(DEBUG)console.log("status", props.status);
  
  // Order
  const order      =    props?.order || {};
  
  // Status
  let status        =   props?.status || 0;

  // If status 
  if( order?.payment_status !== 1 )  status     =    2001;
  
  // Status name
  const statusName =    arrStatus[status] || "Indefinido";



  return(
    <div className={`status status-`+ status}>
      {statusName}
    </div>
  );
}