const variables = {
  production: {
    GOOGLE_MAPS_APIKEY: "AIzaSyD0570LMC5F2TnvoD5AkvjK65XSaFTEVrQ",
    ECARRETO_API_URL  : "https://api.ecarreto.com.br/",
  },
};

const getEnvVariables = () => {
  return variables.production;
};

export default getEnvVariables;
