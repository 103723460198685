import { Component } from "react";
import "./ButtonSejaMotorista.css";
import criarConta from "../../src/assets/Main/icon-caminhao-branco.svg";

class ButtonSejaMotorista extends Component {
  render() {
    const { props } = this;

    const defaultProps = {
      ...props,
      className:
        "btnSejaMotorista btnHome " + ( props.className || "" ),
    };

    return (
      <button {...defaultProps}>
        <div className="imgBtn">
          <img alt="Motorista" className="criarConta " src={criarConta} />
        </div>
        {props.children}
      </button>
    );
  }
}

export default ButtonSejaMotorista;
