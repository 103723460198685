import React, { useContext, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { DateTimePicker, Application } from 'react-rainbow-components';

// Local components
import NavBar from "../../components/NavBar";
import ButtonPadrao from "../../components/ButtonPadrao";
import InputAddProduct from "../../components/InputAddproduct";
import ButtonVoltar from "../../components/ButtonVoltar";
import HelpButton from "../../components/ButtonHelp";
import { shopcartCheck } from "../../Helper/Utils";
import { errorToaster, ToastContainer } from "../../components/Toasters";
import Product from "../../Api/Product";

// Data analysis
import { MP } from "../../components/MixPanel";

// Css
import "./Main.css";
import "./DateTimePicker.css";
import "./react-datetime.css";

// Assets
import transpSegurado from "../../assets/deliveryProfile/transp-segurado.svg";
import caminhao from "../../assets/Main/icon-caminhao-branco.svg";

import calendar from "../../assets/Main/calendar.svg";
import iconSetaEsq from "../../assets/Main/icon-pin-seta-esq.svg";
import iconSetaDir from "../../assets/Main/icon-pin-seta-dir.svg";
import iconCheck from "../../assets/Main/icon-check.svg";
import excluir from "../../assets/orderResume/excluir.svg";

// Delivery Profile
// import caixa from "../../assets/Main/caixa.svg";
import iconTransporte from "../../assets/deliveryProfile/icon-transporte-itens.svg";
import mudancaResidencial from "../../assets/deliveryProfile/home.svg";
import mudancaEmpresarial from "../../assets/deliveryProfile/mudanca-empresarial.svg";

// Routes
import links from "../../config/Links";
import { ShepherdTourContext } from "react-shepherd";

// Main function
export default function Main(props) {

  // Tour
  const tour  = useContext(ShepherdTourContext);
  
  // Navigation
  const history = useHistory();

  // Default delivery type = 1
  const deliveryType = parseInt( localStorage.getItem("deliveryType") || 1 );

  // API modules
  const product = new Product();

  // States
  const [deliveryDate, setDeliveryDate] = useState(suggestDate);
  const [deliveryFrom, setDeliveryFrom] = useState("");
  const [deliveryTo, setDeliveryTo] = useState("");
  const [deliveryDataTo, setDeliveryDataTo] = useState({});
  const [deliveryDataFrom, setDeliveryDataFrom] = useState({});

  // Delivery products
  let deliveryItems = JSON.parse(localStorage.getItem("items")) || [];
  const [list, setList] = useState(deliveryItems);


  // Packages
  const [packageList, setPackageList] = useState({});

  // MP
  MP("Main", { deliveryType, deliveryDate, deliveryFrom, deliveryTo, deliveryDataTo, deliveryDataFrom, deliveryItems, packageList });

  // Try to find selected package
  let packageSelected = 0;
  if( deliveryType === 2 || deliveryType === 3 ){
    // Find package by id and category id // 15 = mudança residencial  // 16 = mudança empresarial
    packageSelected = deliveryItems.find( item => item?.id_category === 15 || item?.id_category === 16 );
    packageSelected = packageSelected?.id || 0;
  } else {
    // If type == 1 turnoff loading beacuse user will use product search
    // setLoading(false);
  }
  // Package selected state
  const [packageId, setPackageId] = useState(packageSelected);

  // Set initial date
  function suggestDate() {
    const dte = new Date();
    let hour = dte.getHours() + 1;
    if (hour > 23) hour = 0;
    dte.setHours(hour, 0, 0, 0);
    return dte;
  }

  function handleShopcartRemove(index) {
    deliveryItems.splice(index, 1)
    localStorage.setItem("items", JSON.stringify(deliveryItems));
    setList(deliveryItems);
  }

  function _handleSaveDate() {

    // Date NOW
    const now = new Date();

    // if user is trying to input an invalid date, check:
    if (deliveryDate < now) {
      // if date in storage is older than today, gives today date

      // sets a suggested date
      setDeliveryDate(suggestDate);

      // catches now and sets it into the the storage
      localStorage.setItem("deliveryType", deliveryType);
      localStorage.setItem("dte", suggestDate);
    } else {
      // if the date is valide, it continues
      localStorage.setItem("deliveryType", deliveryType);
      // Save date
      localStorage.setItem("dte", deliveryDate);
    }

  }

  function handleFinishTour() {
     // Tour controller
     if (localStorage.hasOwnProperty("pagesTourSeen")){
      const restProps = JSON.parse(localStorage.getItem('pagesTourSeen'))
      localStorage.setItem('pagesTourSeen', JSON.stringify({
        ...restProps,
        main: true,
      }))
    }
  }

  async function handleValidateData() {

    // Tour controller
    handleFinishTour()

    // Check if deliveryDate was set
    const deliveryDateStored = (localStorage.getItem("dte") ? true : false);
    if (deliveryDate && !deliveryDateStored) {
      _handleSaveDate();
    }

    // Delivery date
    const selectedData = new Date(deliveryDate);
    // Date now
    const now = new Date();

    if (selectedData < now) {
      errorToaster("Por favor, defina uma data maior que a atual!");
      return;
    }

    // Validation
    const validation = shopcartCheck();

    if ( !validation ) {
      // Which field is empty
      // to review if data is only new Date it does not validate
      if (deliveryDate === "") {
        errorToaster("Por favor, defina uma data!");
      } else if (deliveryFrom === "..." || deliveryFrom === "") {
        errorToaster("Por favor, defina o local de coleta!");
      } else if (deliveryTo === "..." || deliveryTo === "") {
        errorToaster("Por favor, defina o local de entrega!");
      } else if (!deliveryItems.length) {
        // Package or product should be selected
        const msg = deliveryType === 1 ? "Por favor, selecione ao menos um produto para entrega!" : "Por favor, selecione ao menos um pacote para entrega!";
        errorToaster(msg);
      } else {
        // Testing geoposition
        const latAndlongTo    = JSON.parse(localStorage.getItem("latAndlongTo"));
        const latAndlongFrom  = JSON.parse(localStorage.getItem("latAndlongFrom"));
        // console.log("latAndlongTo", latAndlongTo);
        // console.log("latAndlongFrom", latAndlongFrom);

        if ( 
          !latAndlongTo?.lat                 || 
          !latAndlongTo?.lng                 || 
          latAndlongTo?.lat === 0            || 
          latAndlongTo?.lng === 0            || 
          latAndlongTo?.lat === undefined    || 
          latAndlongTo?.lng === undefined    || 
          latAndlongTo?.lat === null         || 
          latAndlongTo?.lng === null 
        ) {
          errorToaster("Não foi possível identificar o geoposicionamento do local de entrega!");
        } else if (  
          !latAndlongFrom?.lat               || 
          !latAndlongFrom?.lng               || 
          latAndlongFrom?.lat === 0          || 
          latAndlongFrom?.lng === 0          || 
          latAndlongFrom?.lat === undefined  || 
          latAndlongFrom?.lng === undefined  || 
          latAndlongFrom?.lat === null       || 
          latAndlongFrom?.lng === null  
        ) {
          errorToaster("Não foi possível identificar o geoposicionamento do local de coleta!");	
        } else {
          // Another Error 
          errorToaster("Erro na validação do pedido. Por favor, selecione novamente a data, local de entrega, colega e produtos e tente novamente!");
        }
      }
      return false;
    }

    // Hard location validation
    let sendToResumeOrderPage = true

    function validateMapsFields(itemFromMapPayload) {

      // if it differs  from required fields:
      if (
        itemFromMapPayload[0] !== "complement"  &&
        itemFromMapPayload[0] !== "postal_code" &&
        itemFromMapPayload[0] !== "floor"
      ) {
        // tests:
        if (itemFromMapPayload[1] === "") {
          sendToResumeOrderPage = false
          return
        }
      }
    }

    Object.entries(deliveryDataFrom).forEach((itemFromMapPayload, index) => {
      validateMapsFields(itemFromMapPayload)
    })

    Object.entries(deliveryDataTo).forEach((itemFromMapPayload, index) => {
      validateMapsFields(itemFromMapPayload)
    })

    // Validation passed
    if (sendToResumeOrderPage) history.push('/resumo-pedido');
  
  } // endif handleValidateData

  // List products : Mudança Residencual
  async function productsByDeliveryType( type ) {
    
    // If 1 ignore package list
    if (type === 1) return;

    // setLoading(true);

    // Get products / package list
    await product.exec("POST", "product/list/", {
      // type: 2 = Mudança Residencial ( cat 15 )
      // type: 3 = Mudança Comercial   ( cat 16 )
      categoryId    : ( type === 2 ? 15 : ( type === 3 ) ? 16 : "" ),
      active        : 1,
      deleted       : 0
    }).then((resp) => {
      if( resp?.rows?.length ){
        setPackageList(resp?.rows);
      } else {
        console.warn("No package list found !");
        errorToaster("Nenhum pacote encontrado para este perfil de transporte!");
      }
    });  
  }

  // Set delivery package
  function _handleSavePackage( id ) {

    // Set package id state
    setPackageId(id);

    // Get package data
    const packageData = packageList.find( (item) => item.id === id );

    // Package product data
    const { depth, width, height, weight } = packageData;

    if(depth === "") {
      errorToaster('Produto indisponível devido a falta de dimensões. ( Profundidade ). Entre em contato com o nosso suporte para ajuda.');
      return 
    }else if ( width === "") {
      errorToaster('Produto indisponível devido a falta de dimensões. ( Largura ). Entre em contato com o nosso suporte para ajuda.')
      return
    } else if (height === "") {
      errorToaster('Produto indisponível devido a falta de dimensões. ( Altura ). Entre em contato com o nosso suporte para ajuda.')
      return 
    }
    else if (weight === "") {
      errorToaster('Produto indisponível devido a falta de dimensões. ( Peso ). Entre em contato com o nosso suporte para ajuda.')
      return 
    }

    // Set item
    const addItem = { ...packageData, observations : "", quantity : 1, depth, width, height, weight };

    // Prepare new array to save
    let items = [];
    
    // Load itens from storage ( remove last itens added )
    // if ( localStorage.hasOwnProperty("items") ) items = JSON.parse(localStorage.getItem("items"));
    
    // Add item to array
    items.push(addItem);

    // Save items - shopcart
    localStorage.setItem("items", JSON.stringify(items));

    // Next step
    handleValidateData();
  }

  useEffect(() => {

    if (localStorage.hasOwnProperty("pagesTourSeen")){
      if(!JSON.parse(localStorage.getItem("pagesTourSeen")).main) {
        tour.start()
      }
    }else {
      tour.start()
    }

    // Getting payload data, in case user just came back from map
    if (localStorage.hasOwnProperty("completeFrom"))
      setDeliveryDataFrom(JSON.parse(localStorage.getItem("completeFrom")));

    if (localStorage.hasOwnProperty("completeTo"))
      setDeliveryDataTo(JSON.parse(localStorage.getItem("completeTo")));

    /**Getting locations name from storage when they change */
    if (localStorage.hasOwnProperty("addressFrom"))
      setDeliveryFrom(JSON.parse(localStorage.getItem("addressFrom")));

    if (localStorage.hasOwnProperty("addressTo"))
      setDeliveryTo(JSON.parse(localStorage.getItem("addressTo")));

    if (localStorage.hasOwnProperty("dte")) {

      let storedDate = new Date((localStorage.getItem("dte")))

      // if date in storage is older than today, sets now date
      if (storedDate < suggestDate) {
        setDeliveryDate(suggestDate);
        _handleSaveDate();
      } else {
        // if the date is valide, it continues
        setDeliveryDate(new Date((localStorage.getItem("dte"))));
      }
    }

    // Call products by delivery type
    productsByDeliveryType(deliveryType);

      // Top page
      try { window.scrollTo(0, 0); } catch (err) {}
  }, []);

  const theme = {
    rainbow: {
      palette: {
        brand: '#ff5e00',
      },
    },
  };

  const containerStyles = {
    maxWidth: 400,
  };


  return (
    <div className="container-principal-Main">
      <NavBar />
      <ToastContainer />
      <div className="container-main-central">
        <div className="container">
          <ButtonVoltar onClick={handleFinishTour} to={links.deliveryProfile} />
          <div className="container-main tour-description-main-page">
            <div className="informacao-central">
              <div>
                <h2 className="tour-description-main-page">Qual a data da coleta?</h2>
                <div className="box tour-main-delivery-date-map">
                  {deliveryDate && deliveryDate !== "Invalid Date" ? (
                    <img className="checked" alt="sinal-de-checado" src={iconCheck} />
                  ) : (
                    <div className="placeholder">Selecione</div>
                  )}

                  <Application className="react-datepicker-wrapper" theme={theme}>
                    <div style={{ containerStyles }}>
                      <DateTimePicker
                        placeholder=""
                        value={deliveryDate || null}
                        minDate={new Date()}
                        onChange={setDeliveryDate}
                        onBlur={_handleSaveDate}
                        hideLabel={true}
                        icon={<></>}
                        locale="pt-BR"
                        okLabel={'Aceitar'}
                        cancelLabel={'Cancelar'}
                        hour24={true}
                        dateFormat={'DD/MM/YYYY'}
                        timeFormat={'HH:mm'}
                      />
                    </div>
                  </Application>

                  <img
                    alt="calendar"
                    className="img-input"
                    src={calendar}
                  />
                </div>
              </div>
              <div>
                <h2>Qual o local de coleta?</h2>
                <Link
                  className="box tour-main-address-from-map"
                  to={{
                    pathname: './onde-vem',
                    props: {
                      state: setDeliveryFrom
                    }
                  }}
                >
                  {deliveryFrom ? (
                    <>
                      <img className="checked" alt="" src={iconCheck} />
                      <div className="box-local">{deliveryFrom}</div>
                    </>
                  ) : (
                    <div className="placeholder">Selecione</div>
                  )}
                  <img alt="" className="img-input" src={iconSetaEsq} />
                </Link>
              </div>
              <div>
                <h2>Qual o local de entrega?</h2>
                <Link className="box tour-main-address-to-map" to={{
                  pathname: './onde-vai',
                  props: {
                    state: setDeliveryTo
                  }
                }}
                >
                  {deliveryTo ? (
                    <>
                      <img className="checked" alt="" src={iconCheck} />
                      <div className="box-local">{deliveryTo}</div>
                    </>
                  ) : (
                    <div className="placeholder">Selecione</div>
                  )}
                  <img alt="" className="img-input" src={iconSetaDir} />
                </Link>
              </div>
            </div>
            <div className="informacao-central-select">

            <div className="search-input-main-container">
            <div className="icon-caixa">
              <div><img alt="caixa" className="img-box-main" src={ deliveryType === 2 ? mudancaResidencial : ( deliveryType === 3 ? mudancaEmpresarial : iconTransporte )} /></div>
              <h2>{ deliveryType === 2 ? "Mudança Residencial" : ( deliveryType === 3 ? "Mudança Empresarial" : "O que vamos entregar?" )}</h2>
            </div>
            <div className="campo-busca-main-central tour-main-search-product">
              { deliveryType === 1 ?
                (
                <div className="delivery-type-1 ">
                  <InputAddProduct />
                  { list.length > 0 &&
                    <div className="cx-info-transp-central">
                      <ul>
                        {list.map((item, index) => (
                          <li key={index}>
                            <div className="lista-item-OrderResume">
                              <div className="lista-item-OrderResume-input-number ">
                                {item.quantity || 1} <span>x</span>
                              </div>
                              <div className="lista-item-OrderResume-input-name" >{item.name}</div>
                              <img
                                alt="excluir"
                                onClick={() => handleShopcartRemove(index)}
                                src={excluir}
                                className="btShopCartRemove"
                              />
                            </div>
                            {item.observations ? (
                              <div className="lista-item-OrderResume-observations">
                                Observações: <b>{item.observations}</b>
                              </div>
                            ) : ""}
                          </li>
                        ))}
                      </ul>
                    </div>
                  }
                </div>
                ) : (
                    <div className={`delivery-type-` + deliveryType}>
                      { 
                      packageList.length > 0 &&
                      <div className="packages">
                        <ul>
                          { packageList.map((item, index) => (
                            <li key={index} onClick={ () => _handleSavePackage(item?.id) }>
                              <h2>{item?.name}</h2>
                              <p>{item?.description}</p>
                              <button className={ packageId === item?.id ? "btnPadraoPeq" : "btnPadraoPretoPeq" }>
                                { /* <input type="checkbox" name="package" value={item?.id} checked={ packageId === item?.id ? true : false } /> */ }
                                <div>{ packageId === item?.id ? "plano selecionado" : "escolher este plano" }</div>
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                      }
                    </div>
                )
              }
              <div className="button-chat tour-main-help-button">
                <p>Não encontrou o que procura ?</p>
                <HelpButton />
              </div>
            </div>
            </div>

            </div>
          </div>
        </div>
      </div>
      <div className="container-inferior-cinza" >
        <div className="container">
          <div className="container-main">
            <div className="posicionamento-main">
              <div className="imgTransp-main">
                <img alt="transpSegurado" src={transpSegurado} />
              </div>
              <div className="btn-finalizar tour-main-foward-button">
                <ButtonPadrao img={caminhao} onClick={() => { handleValidateData() }}>
                  <h5>Continuar</h5>
                </ButtonPadrao>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
