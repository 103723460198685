import React, { useState, useEffect, useContext } from "react";
import { useHistory } from 'react-router-dom'

// Data analysis
import { MP } from "../../../components/MixPanel";

// CSS
import "../Map.css";

// Local components
import NavBar from "../../../components/NavBar";
import ButtonPadrao from "../../../components/ButtonPadrao";
import MapContainer from "../../../components/MapContainer";
import AutoComplete from "../../../components/AutoComplete";
// Assets
// import iconVoltar from "../../assets/Global/icon-voltar-topo.svg";
import transporteSegurado from "../../../assets/map/transporteSegurado.svg";
import avancar from "../../../assets/home/seta-avancar.svg";

import links from "../../../config/Links";
import { errorToaster, ToastContainer } from "../../../components/Toasters";
// import ButtonReturnMap from "../../../components/ButtonReturnMap";
import ButtonVoltar from "../../../components/ButtonVoltar";
import Order from "../../../Api/Order";

// Loading component
import { useLoading } from "../../../components/loading";

// Tour context import 
import { ShepherdTourContext } from "react-shepherd";
import { handleFinishMapTour } from "../../../Helper/Utils";

export default function Map(/* { setShowMap }, */ props) {

  const tour = useContext(ShepherdTourContext)

  const DEBUG = false
  // Navigation
  const history = useHistory();
  // States
  const [autoComplete, setAutoComplete] = useState("");
  const [location, setLocation] = useState(localStorage.getItem("latAndlongTo") || null);

  if (DEBUG) console.log("Local de entrega : ", location);

  // MP
  MP("Map", { "type":"to" }); 

  // Loading Context
  const { setLoading } = useLoading();

  // Api Model
  const apiHandler = new Order()

  async function handleValidateAndSaveData() {
    // Tour control
    handleFinishMapTour()
    setLoading(true)
    let payloadAddress = {}
    let mapReady = "false" //starts blocked
    let floor
    let complement

    if (localStorage.hasOwnProperty("completeTo"))
      payloadAddress = JSON.parse(localStorage.getItem("completeTo"))
    //if(DEBUG) console.log('VALIDATION', payloadAddress)
    if ( localStorage.hasOwnProperty("mapReadyTo") )
      mapReady = JSON.parse(localStorage.getItem("mapReadyTo"))
    localStorage.setItem("completeTo", JSON.stringify(payloadAddress));

    // Treating the floor presentation data
    floor = `, Andar ${payloadAddress.floor}`
    if (payloadAddress.floor === undefined || payloadAddress.floor === "") {
      floor = ""
    }

    complement = `, ${payloadAddress.complement}`
    if (payloadAddress.complement === undefined || payloadAddress.complement === "") {
      complement = ""
    }

    // Precise geocode integration
    apiHandler.exec("POST",`track/geocode`, {
      local: {
        address       :  payloadAddress.street,
        number        :  payloadAddress.number,
        district      :  payloadAddress.hood,
        city          :  payloadAddress.city,
        state         :  payloadAddress.district,
        country       :  payloadAddress.country
      }
    }).then((response) => {
      // OK
      if(DEBUG)console.log("RESPOSTA ENDPOINT GEOCODE", response)

      // Saves into storage
      if(response.success) {
        // Formats the coordinates
        const coordinatesObject = {lat:response.result.latitude, lng: response.result.longitude}
        // console.log("MapTo : coordinatesObject", coordinatesObject);

        // Check Lat x Long
        if(
          coordinatesObject.lat === undefined || 
          coordinatesObject.lng === undefined ||
          coordinatesObject.lat === null ||
          coordinatesObject.lng === null ||
          coordinatesObject.lat === "" ||
          coordinatesObject.lng === ""
        ) {
          errorToaster("Não foi possível identificar o geoposicionamento do endereço informado. Por favor, digite o endereço no campo de busca e tente novamente. Se necessário solicite ajuda ao nosso time de suporte !");
          setLoading(false)
          return
        }

        const hood = response.data[0].extra.neighborhood
        const {streetName, streetNumber, country, zipcode} =  response.data[0]
        const { level2long, level1long} =  response.data[0].administrativeLevels
        
        // console.log("RESULTADO SPLIT", arrayAddress)
        localStorage.setItem(`latAndlongTo`, JSON.stringify(coordinatesObject));
        
        // Getting stairs information
        let stairs  = (JSON.parse(localStorage.getItem("completeTo")).stairs)

        let definitivePayload = {
          street      :  !streetName ? payloadAddress?.street :  streetName,
          number      :  !streetNumber ?  payloadAddress?.number : streetNumber,
          city        :  !level2long ? payloadAddress?.city : level2long,
          hood        :  !hood ? payloadAddress?.hood : hood,
          country     :  !country ? payloadAddress?.county : country === "Brazil" && "Brasil" ,
          district    :  !level1long ? payloadAddress?.district : level1long,
          postal_code :  !zipcode ? payloadAddress?.postal_code : zipcode,
        }

        console.log('Stairs', definitivePayload.stairs)
       // Final input validation
       if(payloadAddress.stairs === "0" && payloadAddress.floor.length) {
        errorToaster("Por favor, informe se a entrega utilizará escadas, elevador ou será entregue na portaria !")
        setLoading(false)
        return
       } else if (definitivePayload.street === "" || definitivePayload.street === undefined) {
          errorToaster("Por favor, revise o campo rua!")
          setLoading(false)
          return
        } else if (definitivePayload.number === "" || definitivePayload.number === undefined) {
          errorToaster("Por favor, revise o campo número!")
          setLoading(false)
          return
        } else if (definitivePayload.city === "" || definitivePayload.city === undefined) {
          errorToaster("Por favor, revise o campo cidade!")
          setLoading(false)
          return
        } else if (definitivePayload.hood === "" || definitivePayload.hood === undefined) {
          errorToaster("Por favor, revise o campo bairro!")
          setLoading(false)
          return
        } else if (definitivePayload.district === "" || definitivePayload.district === undefined) {
          errorToaster("Por favor, revise o campo estado!")
          setLoading(false)
          return
        } else if (definitivePayload.country === "" || definitivePayload.country === undefined) {
          errorToaster("Por favor, revise o campo país!")
          setLoading(false)
          return
        }
        // Syncs the address data
        localStorage.setItem(`completeTo`, JSON.stringify({...payloadAddress, ...definitivePayload}));

        localStorage.setItem("addressTo", JSON.stringify(`
          ${definitivePayload.street},
          ${definitivePayload.number}${complement}${floor},
          ${definitivePayload.hood},
          ${definitivePayload.city},
          ${definitivePayload.district},
          ${definitivePayload.country}  
        `));
        // Capturing the endpoint result
       history.push(links.main)

      }
      // On error
      
    }).catch((error) =>{ 
      setLoading(false)
      console.log(error)
      console.log("Erro geocode", error.response)
      // Custom message from endpoint 
      errorToaster( error.response.data.message_br ||"Erro: Por favor entre com um endereço válido!")
    })

  }

  function handleGetFormData(payload) {
    if (DEBUG) console.log('PAYLOAD COMPLETO TO', payload)
    localStorage.setItem("completeTo", JSON.stringify(payload))
  }
  function handleAutoComplete(autoComplete) {
    setAutoComplete(autoComplete);
    // handleValidateAndSaveData()
  }

  useEffect(() => {
     // Tour controller
     if (localStorage.hasOwnProperty("pagesTourSeen")) {
      if (!JSON.parse(localStorage.getItem("pagesTourSeen")).map) {
        tour.start()
      }
    } else {
      tour.start()
    }
  },[])

  return (
    <>
      <div>
        <ToastContainer />
        <NavBar />
        <div className="container-principal-map ">
          <MapContainer location={location} />
          <div className="container-full-map-page">
            <ButtonVoltar onClick={handleFinishMapTour} to={links.main} />
            <h1>Local de Entrega</h1>
            <div className="input-map">
              <AutoComplete
                onAutoComplete={handleAutoComplete}
                defaultValue={autoComplete || ""}
                onFormData={handleGetFormData}
                mapType="To"
              />
            </div>
          </div>
          <div className="container-inferior-cinza-map">
            <div className="container">
              <div className="margin-Map">
                <div className="container-inferior-map">
                  <div>
                    <img
                      alt="transporteSegurado"
                      className="transpSegurado-map"
                      src={transporteSegurado}
                    />
                  </div>
                  <div className="map-page-finish">
                    <ButtonPadrao onClick={() => handleValidateAndSaveData()} img={avancar}>
                      Selecionar
                    </ButtonPadrao>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
