import React from "react";
import "./Modal.css";
/* import links from "../config/Links";
import { Link } from "react-router-dom"; */

import compartilhar from "../assets/SearchingDriver/icon-compartilhar-branco.svg";
import perfil from "../assets/SearchingDriver/foto-perfil-jose.svg";
import qrcode from "../assets/SearchingDriver/qrcode.svg";

import ButtonPadrao from "../components/ButtonPadrao";

function Modal({ setOpenModal }, ...props) {
  return (
    <div className="backgroundModal">
      <div className="title">
        <h2
          style={{ padding: "5px", cursor: "pointer" }}
          onClick={() => {
            setOpenModal(false);
          }}
        >
          x
        </h2>

        <>
          <div className="perfilModal">
            <div style={{ alignItems: "center" }}>
              <img
                alt="perfil"
                style={{ width: "125px", height: "125px" }}
                src={perfil}
              />
            </div>
          </div>
          <div style={{ marginBottom: "65px" }} className="conteudoModal">
            <h2>Informações de transporte</h2>
            <h3>José Carlos - Volvo FH540 </h3>
            <h3>Cor: Vermelho - Placa final: ***-4554</h3>
            <img alt="qrcode" src={qrcode} />
            <h4>FG876-86JUY</h4>
          </div>
        </>
      </div>
      <div className="btn-Modal">
        <ButtonPadrao
          img={compartilhar}
          style={{ height: "53px", alignItems: "center", padding: "10px" }}
        >
          Compartilhar
        </ButtonPadrao>
      </div>
    </div>
  );
}

export default Modal;
