import React from "react";
import "./style.css";

function ModalBase({ setOpenModal, customMessage, cancelMessage, confirmMessage, customFunction }, ...props) {
  return (
    <div className="backgroundModal-ModalBase ">
      <div className="title-ModalBase">
        <div className="conteudoModal-Base">
          <h2>{customMessage}</h2>
          <div className="actions-ModalBase">
            {/* <button
                className="cancel-button-ModalBase"
              onClick={() => {
                setOpenModal(false);
              }}
              >
              Não, obrigado!
            </button> */}
            <button
              className="buttonModal-Base"
              onClick={() => {
                setOpenModal(false);
                customFunction()
              }}
            >
              {confirmMessage}
            </button>
          </div>

        </div>
      </div>
    </div>
  );
}

export default ModalBase;
