import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Component to display the order running status
import OrderStatus from "../OrderStatus";
// CSS
import "./style.css";
// Api
import Order from "../../Api/Order";
// Links
import links from "../../config/Links";

export function OrderMonitor() {
  const order = new Order();
  const DEBUG = false;

  // Component control states
  const [lastOrders, setLastOrders] = useState([])
  const [hasOrder, setHasOrder] = useState(false);

  const _handleGetOrders = async () => {
    try {

      // User 
      const dadosUsuario = JSON.parse(localStorage.getItem("token"));
      const { user : { id : userId } } = dadosUsuario;

      order.exec("POST", `order/list/?page=1`, { id_user : userId }).then((response) => {
        if (DEBUG) console.log("CALLING ORDER ENDPOINT, RESULT>>>>>", response.rows)
        const now = new Date().toUTCString()
        if (DEBUG) console.log(now)
        if(!response.rows) {
          setHasOrder(false)
          return
        }
        const arrayOrders = response.rows
        let arrayQueuedOrders = []

        // Reduce arrayOrder from user.id = logged user id
        arrayOrders
          .filter((item) => item?.order?.payment_status === 1 ) 
          .map( (item) => {
            if ( ( item.order.status >= 0 && item.order.status <= 20 ) || item.order.status === 40 ) {
              arrayQueuedOrders.push(item)
            }
          })
          setLastOrders(arrayQueuedOrders);
          setHasOrder(true);
          
      });
    }catch(err) {
      console.log(err)
    }
  };

  useEffect(() => {
    // Start
    _handleGetOrders()

        // Watcher
        let timerToCallOrders = setInterval(() => _handleGetOrders(), 5 * 60 * 1000);
        return () => {
          clearInterval(timerToCallOrders);
        };
  }, [])

  if (hasOrder) {
    return (
      <div className="order-monitor">
        <div className="order-monitor__order__slider">
          {/* List of orders */}
          {lastOrders.map((item, index) => {
            const { order } = item;
            return (
              <Link to={{
                pathname: links.trackingmap,
                state: {
                  item: item,
                  accessType: "order-monitor",
                  id: order?.serial
                }
              }} key={index} style={{ textDecoration: "none" }}>

                { /* Order */}
                <div className="order-monitor__order" onClick={() => { }}>
                  <div className="order-monitor__order__header__icon"><div className="lds-ellipsis"><div></div><div></div><div></div></div></div>
                  <div className="order-monitor__order__header__title">Pedido em andamento <br /><b>{order?.serial}</b></div>
                  <div className="order-monitor__order__header__status">
                    <OrderStatus status={order?.status} order={order} />
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}