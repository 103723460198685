import React, { useState, useRef, useContext, useEffect } from "react";
import {version} from '../../../package.json';
import * as Yup from "yup";
import { Form } from "@unform/web";
import { Link, Route } from "react-router-dom";
import { withRouter } from "react-router";
import { Context } from "../../Context/ContextGlobal";
import { errorToaster, successToaster, ToastContainer } from "../../components/Toasters";

// Local components
import { useLoading } from "../../components/loading";
import ButtonFacebook from "../../components/ButtonFacebook";
import ButtonApple from "../../components/ButtonApple";
import ButtonGoogle from "../../components/ButtonGoogle";
import ButtonCriarConta from "../../components/ButtonCriarConta";
import ButtonSejaMotorista from "../../components/ButtonSejaMotorista";
import InputLogin from "../../components/InputLogin";
import links from "../../config/Links";

// Data analysis
import { MP } from "../../components/MixPanel";

// CSS
import "./index.css";

// Assets
import ButtonAvancar from "../../components/ButtonAvancar";
import logo from "../../assets/home/logo-ecarreto.svg";
import transpSegurado from "../../assets/home/transporte segurado.svg";
import boxDelivery from "../../assets/home/box-delivery-agrupado.svg";
import iconVoltar from "../../assets/Global/icon-voltar-topo.svg";
import MobileSwitch from "../../components/MobileSwitch";


// Main function
function Home(props) {

  // MP
  MP("Home");
    
  // Inactivate social login buttons
  const socialLogin = false;

  // Loading
  const { setLoading } = useLoading();

  // Form ref
  const formRef = useRef(null);

  // Login context handle
  const { handleLogin } = useContext(Context);

  // Switch - Salve login data
  const [selected, setSelected] = useState(false);
  const [isBeta, setIsBeta] = useState(false);
  const [dataSt, setDataSt] = useState(localStorage.hasOwnProperty("esln") ? JSON.parse(localStorage.getItem("esln")): {})

    
  async function handleSubmit(data, { reset }) {
      // checking if there is a value saved
      !selected ? localStorage.setItem("esln", JSON.stringify(data)) : localStorage.removeItem("esln", JSON.stringify({}));
  
    // Loading true
    try {
      // Remove all previous errors
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string()
          .email("E-mail inválido.")
          .required("Digite seu e-mail."),
          password: Yup.string().required("Digite sua senha."),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        
      setLoading(true);
      // Validation passed
      const loginResponse = await handleLogin({
        email: data.email,
        password: data.password,
      });

      // Login
      if (loginResponse.success) {
        // Login success
        // alert.success("Login ok !", { title: "Login", closeCopy: "Ok" });

        // MP
        MP("Login", { "success":"true" , "email": data.email });

        // Loading false
        // setLoading(false);

        // Redir
        window.location.href = "/tipo-entrega";
      } else {
        // Login error
        // User message
        setLoading(false);

        errorToaster("Erro ao realizar o login. Verifique os dados inseridos e tente novamente.")
        // alert.info(
        //   "Erro ao realizar o login. Verifique os dados inseridos e tente novamente.",
        //   {
        //     title: "Login",
        //     closeCopy: "Ok",
        //     // actions: [
        //     //   {
        //     //     copy: "Sim",
        //     //     onClick: () => {},
        //     //   },
        //     // ],
        //   }
        // );
        // MP
        MP("Login", { "success":"false", "email": data.email });        


        // Loading false
      }
    } catch (err) {
      /* const validationErrors = {}; */
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
      
      // MP
      MP("Login", { "error":"true", "email": data.email }); 

      // Loading false
      setLoading(false);
    }
  }

  // Handle navigator returns
  function getReturns(){ 
    // Result passed by location
    const result = props?.location?.state?.result;
    if( result ) {
      const { success, message_br } = result;
      if( success )
        successToaster(message_br);
      else
        errorToaster(message_br);
    }  
  }

  useEffect(() => {
    // console.log(dataSt)
    try{
      const mainURL = window.location.href; 
      // Set beta
      if( mainURL.includes("appbeta.") ) setIsBeta(true);
    } catch(er){}

    getReturns();    

    // Top page
    try { window.scrollTo(0, 0); } catch (err) {} 
  },[])

  return (
    <>
      <ToastContainer />
      <div className="container-principal-Home">
        <div className="container">
          <div className="container-superior">
            <div className="cabecalho-home">
              <div className="logo-home">
                <img className="logo-home-img" alt="e-Carreto - Fretes, carretos e entregas" title="e-Carreto - Fretes, carretos e entregas" src={logo} />
                <div className="app-beta">{isBeta && "Beta "}{version}</div> 
                <h1 className="slogan">
                  Solicite transporte agora, com segurança e rapidez
                </h1>
              </div>
              <img className="selo-transporte-segurado" alt="transp-segurado" src={transpSegurado} />
              <div className="mobile-arrow-down"><a href="#myform"><img alt="Descer" title="Descer" className="arrow" src={iconVoltar} /></a></div>
            </div>
          </div>
        </div>
        <div className="container-body">
          <div className="container-conteudo">
            <div className="imgBox">
              <img alt="boxDelivery" src={boxDelivery} />
            </div>
            <div>
              <h4>Cadastre-se ou acesse sua conta</h4>
              <div>
                <Link className="link" to={links.accountScreen}>
                  <ButtonCriarConta>
                    <span>Criar conta</span>
                  </ButtonCriarConta>                
                </Link>            
                <Link className="link"  to={{ pathname: "https://ecarreto.com.br/seja-transportador.html" }} target="_blank">
                  <h4 className="home-cad-motorista">Deseja ser motorista parceiro ?</h4>
                  <ButtonSejaMotorista>
                    <span>Motorista</span>
                  </ButtonSejaMotorista>  
                </Link>
              </div>
              {socialLogin && (
                <div className="social-login">
                  <div>
                    <ButtonGoogle>
                      <span>Google</span>
                    </ButtonGoogle>
                  </div>
                  <div>
                    <ButtonFacebook>
                      <span>Facebook</span>
                    </ButtonFacebook>
                  </div>
                  <div>
                    <ButtonApple>
                      <span>Apple</span>
                    </ButtonApple>
                  </div>
                </div>
              )}
            </div>
            <Form
              className="login"
              id="myform"
              ref={formRef}
              onSubmit={handleSubmit}
            >
              <h4> Já sou cadastrado</h4>
              <div className="inputField">
                <InputLogin
                  name="email"
                  type="email"
                  placeholder="Digite seu e-mail"
                  defaultValue={dataSt.email}
                />
              </div>
              <div className="inputField">
                <InputLogin
                  name="password"
                  type="password"
                  placeholder="Digite sua senha"
                  defaultValue={dataSt.password}
                />
              </div>
              <div className="switch">
                <MobileSwitch
                  selected={selected}
                  toggleSelected={() => setSelected(!selected)}
                />
                <p>Salvar informações de login</p>
              </div>
              <div className="esqueci-senha">
                <Link to={links.lostPass}>
                  <p>Esqueci minha senha</p>
                </Link>
              </div>
              <div>
                <ButtonAvancar style={{width: "284px"}} Form="myform" type="submit">
                  <span>Avançar </span>
                </ButtonAvancar>
              </div>
            </Form>            
          </div>         
        </div>
      </div>
    </>
  );
}
export default withRouter(Home);
