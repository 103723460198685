import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";

import "./InputForm.css";

export default function InputForm({ name, errorInput,  ...rest}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <div className={!errorInput ? "inputForm": "inputForm-error"}>
      {error && <span className="inputError">{error}</span>}
      <input  ref={inputRef} defaultValue={defaultValue} {...rest} />
    </div>
  );
}
