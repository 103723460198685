import { useState, useContext, useEffect } from "react";
import "../DeliveryProfile/DeliveryProfile.css";
import NavBar from "../../components/NavBar";
import iconTransporte from "../../assets/deliveryProfile/icon-transporte-itens.svg";
import mudancaResidencial from "../../assets/deliveryProfile/home.svg";
import mudancaEmpresarial from "../../assets/deliveryProfile/mudanca-empresarial.svg";
import transpSegurado from "../../assets/deliveryProfile/transp-segurado.svg";
import ButtonPadrao from "../../components/ButtonPadrao";
import avancar from "../../assets/home/seta-avancar.svg";
import links from "../../config/Links";
import { Link, useHistory } from "react-router-dom";

// Data analysis
import { MP } from "../../components/MixPanel";

import {ShepherdTourContext } from 'react-shepherd';
import ModalTour from "../../components/Tour/ModalTour";

export default function DeliveryProfile({setSteps, ...props}) {

  // MP
  MP("Delivery Profile");

  const tour = useContext(ShepherdTourContext);
  
  // Stored delivery type
  const deliveryTypeStored = localStorage.getItem("deliveryType") || "";

  let modalTourActivated  = !localStorage.hasOwnProperty("pagesTourSeen")
  if (localStorage.hasOwnProperty("pagesTourSeen")) {
    modalTourActivated = JSON.parse(localStorage.getItem("pagesTourSeen")).modal_show === true ? true  : false
  }
  
  // Delivery type state 
  const [deliveryType, setDeliveryType] = useState( parseInt(deliveryTypeStored) || 1);
  const [modalOpen, setModalOpen] = useState(modalTourActivated);
    
  // Navigation
  const history = useHistory();

  // Save to localStorage
  async function handleSaveProfileDelivery(type) {

    if (localStorage.hasOwnProperty("pagesTourSeen")){
      const restProps = JSON.parse(localStorage.getItem('pagesTourSeen'))
      localStorage.setItem('pagesTourSeen', JSON.stringify({
        ...restProps,
        delivery_type: true,
      }))
    } else {
      localStorage.setItem('pagesTourSeen', JSON.stringify({
        delivery_type: true,
      }))
    }

    // Save to localStorage
    await localStorage.setItem("deliveryType", type);
  }
  // Button focus
  async function goTo( type ){
    if(tour.isActive()) return
    if( !type ) type = deliveryType || 1;

    // Save to localStorage
    await handleSaveProfileDelivery(type);

    // Set type
    setDeliveryType(type);

    // Scroll down
    // document.getElementById("btGo").scrollIntoView({behavior: 'smooth'}, true);

    // Go to next page
    history.push({
      pathname: links.main,
      state: {
        deliveryType : type
      }    
    });    
  }

  useEffect(() => {

    if(!modalOpen ){
      if (localStorage.hasOwnProperty("pagesTourSeen")){
        if(!JSON.parse(localStorage.getItem("pagesTourSeen")).delivery_type) {
          tour.start()
        }
      }else {
        tour.start()
      }

    }

  },[modalOpen])

  // Top page
  useEffect(() => {
    try { window.scrollTo(0, 0); } catch (err) {}
  }, []);  

  return (
    <div className="container-DeliveryProfile-principal">
      <NavBar />
      {modalOpen &&
        <ModalTour
          setOpenModal={setModalOpen}
          url={""}
        />}
      <div className="container-central-DeliveryProfile">
        <h1> Escolha o tipo de carga</h1>
        <div className="container">
          <div className="container-DeliveryProfile">
            <div className="container-tipo-carga">
              <div
                className={"cx-tipo-carga tour-description-delivery-items " + (deliveryType === 1 ? " on" : null)}
                onClick={() => {goTo(1)}}
              >
                <img alt="iconTransporte" src={iconTransporte} />
                <span>Transporte de itens</span>
              </div>
              <div
                className={"cx-tipo-carga tour-description-delivery-home " + (deliveryType === 2 ? " on" : null)}
                onClick={() => goTo(2)}
              >
                <img alt="mudancaResidencial" src={mudancaResidencial} />
                <span>Mudança residencial</span>
              </div>
              <div
                className={"cx-tipo-carga tour-description-delivery-enterprise " + (deliveryType === 3 ? " on" : null)}
                onClick={() => goTo(3)}
              >
                <img alt="mudancaEmpresarial" src={mudancaEmpresarial} />
                <span>Mudança empresarial</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-inferior-cinza">
        <div className="container">
          <div className="container-DeliveryProfile">
            <div className="posicionamento">
              <div className="img-transpSegurado-deliveryProfile">
                <img alt="transpSegurado" src={transpSegurado} />
              </div>
              <div className="tour-delivery-foward-button">
                  <ButtonPadrao id="btGo" img={avancar} onClick={ () => goTo(deliveryType) }>Avançar</ButtonPadrao>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
