import PropTypes from "prop-types";
import React, { Component } from "react";

import "./Switch.css";

export class Switch extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { selected, toggleSelected } = this.props;
    return (
      <div
        className={`toggle-container ${ selected ? "off" : "on"}`}
        onClick={toggleSelected}
      >
        <div className="dot"></div>
      </div>
    );
  }
}

Switch.propTypes = {
  selected: PropTypes.bool.isRequired,
  toggleSelected: PropTypes.func.isRequired,
};
export default Switch;
