import { Component } from "react";
import "./ButtonGoogle.css";
import google from "../../src/assets/home/google.svg";

class ButtonGoogle extends Component {
  render() {
    const { props } = this;

    const defaultProps = {
      ...props,
      className:
        "btnGoogle btnHome " + (props.className ? props.className : ""),
    };

    return (
      <button {...defaultProps}>
        <div className="imgBtn">
          <img alt="" className="google" src={google} />
        </div>
        {props.children}
      </button>
    );
  }
}

export default ButtonGoogle;
