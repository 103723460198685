import { useState } from "react";
import { Link } from "react-router-dom";
import { Offline, Online } from "react-detect-offline";
import { OrderMonitor } from "../../src/components//OrderMonitor";  

// Routes
import links from "../../src/config/Links";

// Css
import "../../src/components/NavBar.css";

// Assets
import logo from "../../src/assets/NavBar/logo.svg";
import hamburguer from "../../src/assets/NavBar/hamburguer.svg";
import ModalMenu from "../../src/components/ModalMenu";

export default function NavBar() {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="container-navbar">
      <div className="components-nav">
        <div className="top-logo">
          <Link to={links.main}>
            <img alt="logo" src={logo} />
          </Link>
        </div>
        <div className="components-nav-bts">
          <div className="components-nav-internet">
            <Offline>
              <div className="internet-error">
                <span>Sem conexão com a internet</span>
                <span className="icon-loading"></span>
              </div>
            </Offline>
            <Online>
              <div className="internet-ok">
                <span></span>
                <span className="icon-loading"></span>
              </div>
            </Online>
          </div>
          <img alt="Menu" className="img-navBar" src={hamburguer} onClick={() => setModalOpen(true)}/>
          <ModalMenu open={modalOpen} setOpenModal={setModalOpen} />
        </div>
      </div>
      <OrderMonitor />
    </div>
  );
}
