import { useContext, useEffect, useState } from "react";
import { version } from '../../package.json';
import { Link } from "react-router-dom";
import { Context } from "../Context/ContextGlobal";
import Auth from '../Api/Auth'

import { useLoading } from "./loading";

// CSS
import "./ModalMenu.css";

// Assets
import fechar from "../assets/Menu/fechar.svg";
import perfil from "../assets/Menu/perfil.svg";

// Routes
import links from "../config/Links";
import MobileSwitch from "./MobileSwitch";

function ModalMenu({ setOpenModal, open }, ...props) {

  const { handleLogout, user } = useContext(Context);

  // FormValues Util State
  const [name, setName] = useState('')
  const [cpf, setCpf] = useState('') //int string float double 
  const [email, setEmail] = useState('')
  const [cellphone, setCellphone] = useState('')
  const [password, setPassword] = useState('')
  // const [confirmPassword, setConfirmPassword] = useState('')
  const [avatar, setAvatar] = useState()
  const [urlProfile, setUrlProfile] = useState(avatar)

  let switchActivated = false
  if (localStorage.hasOwnProperty("pagesTourSeen")) 
    switchActivated = JSON.parse(localStorage.getItem("pagesTourSeen")).tour
  
  // console.log( "MODAL TOUR", switchActivated)
  const [isTourActive, setIsTourActive] = useState(!switchActivated)

  const { setLoading } = useLoading();

  // User data
  let userName = "";
  try {
    userName = user.user.name;
    // avatarUrl = user.user.avatar_url


  } catch (err) {
    // User not logged
    userName = "";
  }

  useEffect(() => {

    // Loading
    setLoading(true);

    // Auth API
    const auth = new Auth();

    // Exec request
    auth.me()
      .then((response) => {
        // Setter State
        setName(response.name)
        setCpf(response.cpf)
        setEmail(response.email)
        setCellphone(response.cellphone)
        if(response.avatar) {
            setUrlProfile(response.avatar_url + "?nocache=" + (new Date).getTime().toString())
        }

      })
      .catch((error) => {
        console.log("Error", error);
        // User message
        // alert.removeAll();
        // alert.info(
        //   "Ocorreu um erro ao acessar seus dados cadastrais. Se o problema persistir entre em contato com nosso time de suporte.",
        //   {
        //     title: "e-Carreto",
        //     closeCopy: "Ok"
        //   }
        // );
      });

    // Loading
    setLoading(false);

  }, [setLoading, open]);

  // Class menu modal
  const classModal = open ? "modalContainer on" : "modalContainer off";

  return (
    <div className={classModal}>
      <div className="titleCloseBtn">
        <img
          alt="Fechar menu"
          onClick={() => setOpenModal(!open)}
          src={fechar}
        />
      </div>
      <div className="perfil-menu-modal">
        {urlProfile ? <img className="profile-image-menu-modal" alt={name} src={urlProfile} /> :  <img className="profile-image-menu-modal" alt={name} src={perfil} />}
      </div>
      <div className="dados-menu-modal">
        <h2>{userName}</h2>
        <div className="border-info-modal">
          <div className="info-menu-modal">
            <Link to={links.main}>Inicial</Link>
            <Link to={links.deliveryProfile}>Tipo de carga</Link>
            <Link to={links.userData}>Dados cadastrais</Link>
            <Link to={links.tracking}>Meus transportes</Link>
            { /* <Link to={links.payments}>Pagamentos</Link> */}
            <Link to={links.faq}>Dúvidas frequentes</Link>
            <Link to={links.legalInfoPage}>Informações legais</Link>
            <Link to={"#"} onClick={handleLogout}>Sair</Link>
            <div className="action-tour-modal-menu">
              <MobileSwitch
                selected={isTourActive}
                toggleSelected={() => {
                  console.log( "Dentro do switch", isTourActive)
                    setIsTourActive(!isTourActive);

                    localStorage.setItem("pagesTourSeen", JSON.stringify({
                      tour: isTourActive,
                      modal_show: isTourActive,
                      delivery_type: !isTourActive,
                      main: !isTourActive,
                      order_resume: !isTourActive,
                      order_contact: !isTourActive,
                      user_data: !isTourActive,
                      map: !isTourActive,
                      account_screen: !isTourActive,
                      searching_driver: !isTourActive,
                      lost_pass: !isTourActive,
                      product_add: !isTourActive,
                      tracking: !isTourActive,
                      tracking_map: !isTourActive
                    }));
                }}
              />
              <Link to={"#"}>Ativar instruções</Link>
            </div>

          </div>
        </div>
        <div className="version">
          <p>Versão {version}</p>
        </div>
      </div>
    </div>
  );
}

export default ModalMenu;
