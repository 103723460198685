import React, { useRef, useContext, useEffect} from "react";
import { Context } from "../../Context/ContextGlobal";
import Auth from "../../Api/Auth";
import { withRouter } from "react-router";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { validateCPF } from "validations-br";

// Data analysis
import { MP } from "../../components/MixPanel";

// Local components
import links from "../../config/Links";
import InputForm from "../../components/InputForm";
import InputMask from "../../components/InputMask";
import ButtonAvancar from "../../components/ButtonAvancar";
import ButtonVoltar from "../../components/ButtonVoltar";
import InputMaskPhone from "../../components/InputMaskPhone";

import { useLoading } from "../../components/loading";

// import 

// Css
import "./AccountScreen.css";

// Assets
import pageImage from "../../assets/AccountScreen/account.svg";

//Toaster
import { errorToaster, ToastContainer } from "../../components/Toasters";
// Tour context
import { ShepherdTourContext } from "react-shepherd";

// Main function
function AccountScreen(props) {

  // Tour context
  const tour = useContext(ShepherdTourContext)
  // Form ref
  const formRef = useRef(null);

  // Alert
  // const alert = useAlert();

  // Loading
  const { setLoading } = useLoading();

  // setLoading(true)

  // Login context handle
  const { handleLogin } = useContext(Context);

  // Validation login
  async function userLogin(data){
      // Login user
      const loginResponse = await handleLogin({
        email: data.email,
        password: data.password,
      });

      // Login
      if ( loginResponse.success ) {
        
        // MP
        MP("Account Creation", { "success":"true", "email": data.email });  

        try{
          // Conversion
          window["gtag"]('event', 'conversion', {'send_to': 'AW-318477735/g1axCJDtkdcDEKer7pcB'});
        } catch(error){
          console.log("Google track conversion error", error);
        }
        // Redir
        window.location.href = "/tipo-entrega";
      } else {
        // Login error
        setLoading(false);
        // MP
        MP("Account Creation", { "success":"false", "email": data.email });   
        // Error
        errorToaster("Desculpe, houve um erro ao realizar o login. Entre em contato com nossa equipe de atendimento.");
      }    
  }

  // Add user
  async function addAccount(data) {
    // Loading
    setLoading(true);

    const auth = new Auth();
    auth
      .add(data)
      .then((response) => {
        // console.log("RESPONSE DATA", response.data.message)
    
          // successToaster(response.data.message[0])
          // Add user sucess
          // Login user
          // makes the login
          userLogin(data);
      })
      .catch((error) => {
        // Loading off
        setLoading(false)
        const messageError = ( error.response.data.message_br||error.response.data.message||"Houve um erro ao realizar seu cadastro. Confira os dados informados e tente novamente. Se o problema persistir entre em contato com nosso suporte." )
        errorToaster(messageError)
        console.log(error.response.data.message_br)

      
        // alert.info(error.response.data.message_br, { title: "Cadastro", closeCopy: "Ok" });
      });
  }

  function handleFinishTour ()  {
    // Tour controller
    if (localStorage.hasOwnProperty("pagesTourSeen")){
      const restProps = JSON.parse(localStorage.getItem('pagesTourSeen'))
      localStorage.setItem('pagesTourSeen', JSON.stringify({
        ...restProps,
        account_screen: true,
      }))
    }
    localStorage.setItem('pagesTourSeen', JSON.stringify({
      account_screen: true,
    }))
  }

  async function handleSubmit(data, { reset }) {
    // finishes the tour if user go foward
    // handleFinishTour()
  
    try {
      // Remove all previous errors
      formRef.current.setErrors({});
      // Validations
      const schema = Yup.object().shape({
        email: Yup.string()
          .email("E-mail inválido")
          .required("Informe seu e-mail."),
        name: Yup.string().required("Informe seu nome.").matches(
          /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
          'Nome deve conter apenas letras.'
        )
          .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, 'Informe o nome completo.'),
        cpf: Yup.string()
          .test("is-cpf", "CPF inválido", (value) => validateCPF(value))
          .required("Informe seu CPF."),
        phone: Yup.string()
          .min(8, "O celular deve conter ao menos 8 dígitos.")
          .required("Informe seu celular."),
        password: Yup.string()
          .min(4, "A senha deve conter ao menos 4 dígitos.")
          .required("Informe sua senha."),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      // Validation passed
      addAccount({
        email: data.email,
        name: data.name,
        password: data.password,
        cpf: data.cpf,
        cellphone: data.phone,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) { 
        const errorMessages = {};
        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);

        errorToaster("Revise os campos informados e tente novamente")
      }
    }
  }

  useEffect(() => {
    //     // Tour controller
    //     if (localStorage.hasOwnProperty("pagesTourSeen")){
    //     if(!JSON.parse(localStorage.getItem("pagesTourSeen")).account_screen) {
    //       tour.start()
    //     }
    //   }else {
    //     tour.start()
    //   }
    // Top page
    try { window.scrollTo(0, 0); } catch (err) {}  
  }, [])

  return (
    <>
      <Form id="Cadastro" ref={formRef} onSubmit={handleSubmit}>
        <div className="container-principal">
          <div className="container">
            <ToastContainer/>
            <div className="container-AccountScreen-main">
              <div className="container-backButton">
              <ButtonVoltar to={links.home} />
              </div>
              <div className="container-form tour-account-fields">
                <div className="column">
                  <h1>Preencha seus dados</h1>
                  <p className="container-description">
                    Insira os dados para se cadastrar.
                  </p>
                    <div className="inputField">
                      <InputForm
                        name="name"
                        type="name"
                        placeholder="Nome completo"
                      />
                    </div>
                    <div className="inputField">
                      <InputMask
                        name="cpf"
                        mask="999.999.999-99"
                        maskChar=" "
                        type="cpf"
                        placeholder="CPF"
                      />
                    </div>
                    <div className="inputField">
                      {/* <InputMask
                        type="phone"
                        name="phone"
                        mask="(99)99999-9999"
                        maskChar=" "
                        placeholder="Celular "
                      /> */}
                    <InputMaskPhone
                      id="phone"
                      name="phone"
                      placeholder="Celular"
                    />
                    </div>
                    <div className="inputField">
                      <InputForm 
                        name="email" 
                        type="email" 
                        placeholder="E-mail" 
                      />
                    </div>
                    <div className="inputField">
                      <InputForm
                        name="password"
                        type="password"
                        placeholder="Insira sua senha"
                      />
                    </div>
                </div>
                <div className="column detail">
                  <img src={pageImage} alt="Cadastro" />
                </div>
              </div>
            </div>
          </div>
          <div className="container-inferior-AccountScreen">
            <ButtonAvancar className=" tour-account-finish" Form="Cadastro" type="submit">
              Avançar
            </ButtonAvancar>
          </div>
        </div>       
      </Form>
    </>
  );
}
export default withRouter(AccountScreen);
