import React, { useEffect, useState, useMemo, useCallback } from "react";
import { GoogleMap, Marker, useJsApiLoader,  DirectionsService, DirectionsRenderer  } from "@react-google-maps/api";
import ENV from "../env";
// import Local from "../Api/Local";

import "./MapContainer.css";

const containerStyle = {
  width: "100vw",
  height: "100vh",
};

// Initial location
const initialLocation = {
  lat: -23.5489,
  lng: -46.6388,
};

function MapContainer(props) {

  // Initial
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: ENV().GOOGLE_MAPS_APIKEY,
  });

  // Initial location
  const [location, setLocation] = useState( strToLocation(props.location) || initialLocation );
  // Direction
  const [origin, setOrigin]           =   useState(null);
  const [destination, setDestination] =   useState(null);
  const [response, setResponse]       =   useState(null);

  // Direction service options
  const directionsServiceOptions = useMemo(() => {
    return {
      origin,
      destination,
      travelMode: "DRIVING",
    };
  }, [origin, destination]);

  // String to Google Location
  function strToLocation(loc){

    // Set to object
    try{
      // Try parse - if not ignore
      if(typeof loc === 'string') loc = JSON.parse(loc);
    } catch(e){
      // Log error
      console.warn("Could not parse location", "Type:", typeof loc, "Data:", loc);
      return;
    }

    if( !loc?.lat || !loc?.lng ) return null;
    
    // Lat Long
    const lat = parseFloat(loc.lat);
    const lng = parseFloat(loc.lng);

    if( !isNaN(lat) && !isNaN(lng) )  {
      const ret = { lat, lng };
      return ret;
    } else                              
      return false;
  }

  // Direction callback
  const directionsCallback = useCallback((res) => {
    if (res !== null && res.status === "OK") {
      setResponse(res);
    } else {
      console.log("directionsCallback error", res);
    }
  }, []);  

  // Direction render options
  const directionsRendererOptions = useMemo(() => {
    return {
      directions: response,
    };
  }, [response]);    


  // Set the initial center of the map
  async function setLocal(position) {

    try {
  
      // Geo
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;

      // console.log("##############################################");
      // console.log("####          LOCALIZAÇÃO INICIAL         ####");
      // console.log("##############################################");
      // // console.log(" Position :", position);
      // console.log(" Lat :", lat);
      // console.log(" Lon :", lng);
      // console.log("##############################################");

      if (!lat || !lng) return false;
      
      // Set local 
      setLocation({lat,lng,});

    } catch (e) {
      // Error finding local
      console.log("Error finding local:", e);
    }
  }


  useEffect(() => {

    // User location
    // Check location status ( granted or not )
    const locationStatus = "geolocation" in navigator;
    if ( locationStatus ) navigator.geolocation.getCurrentPosition(setLocal);

    // Direction
    if( props.origin && props.destination ){

      // Convert lat lng from string to float(double)
      const originFormatted         =   strToLocation(props.origin);
      const destinationFormatted    =   strToLocation(props.destination);
    
      // Save to state
      setOrigin(originFormatted);
      setDestination(destinationFormatted);
  
      // console.log("##############################################");
      // console.log("####      ROTA - COLETA ---> ENTREGA      ####");
      // console.log("##############################################");
      // console.log(" Coleta :", originFormatted);
      // console.log(" Entrega :", destinationFormatted);
      // console.log("##############################################");
    }    
  },[props.destination, props.origin])

  return isLoaded ? (
    <div className="container-map">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={location || initialLocation }
        zoom={15}
      >
        <Marker position={location || initialLocation} />
        {origin && destination && directionsServiceOptions && (
            <DirectionsService
              options={directionsServiceOptions}
              callback={directionsCallback}
            />
        )}
        {response && directionsRendererOptions && (
          <DirectionsRenderer options={directionsRendererOptions} />
        )}        
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
}

export default (MapContainer);
