import PropTypes from "prop-types";
import React, { Component } from "react";

import "./style.css";

export class MobileSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { selected, toggleSelected } = this.props;
    return (
      <div
        className={`toggle-container-mobile ${ selected ? "off" : "on"}`}
        onClick={toggleSelected}
      >
        <div className="dot-mobile"></div>
      </div>
    );
  }
}

MobileSwitch.propTypes = {
  selected: PropTypes.bool.isRequired,
  toggleSelected: PropTypes.func.isRequired,
};
export default MobileSwitch;
