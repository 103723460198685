import React, { useState, useRef } from "react";

import Chevron from "../components/Chevron";

import "./AccordionHelpPage.css";

function AccordionHelpPage(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active_Help" : "");
    setHeightState(
      setActive === "active_Help" ? "0px" : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === "active_Help"
        ? "accordion__icon_Help"
        : "accordion__icon rotate"
    );
  }

  return (
    <div className="accordion__section_Help">
      <button
        className={`accordion_Help ${setActive}`}
        onClick={toggleAccordion}
      >
        <h3 className="accordion__title_Help">{props.title}</h3>
        <Chevron className={`${setRotate}`} width={10} /* fill={"#777"} */ />
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content_Help"
      >
        <div
          className="accordion__text_Help"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      </div>
    </div>
  );
}

export default AccordionHelpPage;
