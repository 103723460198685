import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Error Toaster
export const errorToaster = (string, onCloseFunction) => toast.error(string, {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  onClose: onCloseFunction ? onCloseFunction:  () => {}
});

// Success Toaster
export const successToaster = (string, onCloseFunction) => toast.success(string, {
    position: "top-center",
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    onClose: onCloseFunction ? onCloseFunction:  () => {}
  });

// Warning Toaster
export const warningToaster = string => toast.warn(string, {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
});



export {ToastContainer}
