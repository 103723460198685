
// Page routes
const links = {
    home            :   "./",
    accountScreen   :   "./criar-conta",
    lostPass        :   "./senha-perdida",
    passRecovery    :   "./senha-recuperacao",
    deliveryProfile :   "./tipo-entrega",
    main            :   "./principal",
    whereTo         :   "./onde-vai",
    whereFrom        :  "./onde-vem",
    orderResume     :   "./resumo-pedido",
    orderContact    :   "./pedido-pagamento",
    userData        :   "./dados-usuario",
    tracking        :   "./Tracking",
    payments        :   "./pagamento",
    legalInfoPage   :   "./LegalInfoPage",
    helpPage        :   "./ajuda",
    map             :   "./Map",
    productsAdd     :   "./adicionar-produto",
    searchingDriver :   "./encontre-motorista",
    trackingmap     :   "./pedido",
    faq             :   "./faq",
    test           :    "./test",
};

export default links;
