import { Component } from "react";
import "./ButtonAvancar.css";
import avancar from "../../src/assets/home/seta-avancar.svg";

class ButtonAvancar extends Component {
  render() {
    const { props } = this;

    const defaultProps = {
      ...props,
      className: "btnPadraoPreto btnAvancar" + ( props.className || "" ),
    };

    return (
      <button {...defaultProps}>
        {props.children}
        <div className="imgBtn">
          <img alt="Avançar" src={avancar} />
        </div>
      </button>
    );
  }
}

export default ButtonAvancar;
