import React from "react";
import "./styles.css";
/* import links from "../config/Links";
import { Link } from "react-router-dom"; */

// import compartilhar from "../assets/SearchingDriver/icon-compartilhar-branco.svg";
// import perfil from "../assets/SearchingDriver/foto-perfil-jose.svg";
// import qrcode from "../assets/SearchingDriver/qrcode.svg";

// import ButtonPadrao from "../components/ButtonPadrao";

function ModalPayment({ setOpenModal}, ...props) {

  const onClick = () => {
    // Getting url from storage and and accessing it
    let url
    if (localStorage.hasOwnProperty("pLin")) {
      url = JSON.parse(localStorage.getItem("pLin"));
    }
    // Passing for the next window
    window.open(url)
  }

  return (
    <div className="backgroundModal-PaymentModal ">
      <div className="title-PaymentModal">
          <div style={{ marginBottom: "65px" }} className="conteudoModal">
            <h2>Aguardando Pagamento</h2>
            <h3>Caso a tela de pagamento não seja aberta automaticamente,
              <span onClick={onClick}> clique aqui</span>
            </h3>
            <button
              onClick={() => {
                setOpenModal(false);
              }}
              className="buttonModal"
            >
              Fechar
            </button>
          </div>
      </div>
    </div>
  );
}

export default ModalPayment;
