// Components
import { useContext, useEffect, useRef } from "react";
import Auth from "../../Api/Auth";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { validateCPF } from "validations-br";
// import { useAlert } from "react-alert";

// Data analysis
import { MP } from "../../components/MixPanel";

// Local components
import { useLoading } from "../../components/loading";
import links from "../../config/Links";
import ButtonAvancar from "../../components/ButtonAvancar";
import InputForm from "../../components/InputForm";
import InputMask from "../../components/InputMask";
import ButtonVoltar from "../../components/ButtonVoltar";

// CSS
import "./LostPass.css";

// Images
import pageImage from "../../assets/lostPass/senha-recuperacao.svg";
import { errorToaster, successToaster, ToastContainer } from "../../components/Toasters";

// Tour context
import { ShepherdTourContext } from "react-shepherd";

function RecoveryPass(props) {

  // Tour control
  const tour = useContext(ShepherdTourContext)

  // Load control
  const formRef = useRef(null);
  
  // Alert 
  // const alert = useAlert();

  // Loading
  const { setLoading } = useLoading();

  async function recovery(data) {
    
    // Loader
    setLoading(true);
    
    // Auth API
    const auth = new Auth();

    // Exec request
    await auth.lostPass(data)
        .then((ret) => {
          if ( ret && ret.success ) {
            // Success
            // alert.success(ret.message_br, { title: "Recuperação de senha", closeCopy: "Ok" });
            successToaster(ret.message_br);

            // MP
            MP("Lost Pass", { "success":"true", "email": data.email, "cpf": data.cpf });
                        
            // Redir 
            props.history.push("/", { result : ret });
          } else {
              // MP
              MP("Lost Pass", { "success":"false", "email": data.email, "cpf": data.cpf });
                            
              // E-mail and  CPF not found
              // alert.info(ret.message_br, { title: "Recuperação de senha", closeCopy: "Ok" });
              errorToaster(ret.message_br);
          }
          setLoading(false)
        })
        .catch((error) => {

          // MP
          MP("Lost Pass", { "error":"true", "email": data.email, "cpf": data.cpf });
                    
          if (error.response) {
            // Erro
            // console.log(error.response.data.message_br); // => the response payload 
            // alert.info(error.response.data.message_br, { title: "Recuperação de senha", closeCopy: "Ok" });
            errorToaster(error.response.data.message_br);          
          } else {
            // Undefined error
            errorToaster("Ocorreu um erro não identificado. Tente novamente mais tarde.");  
          }
        });

        setLoading(false)
  }

  // function handleFinishTour() {
  //   // Tour controller
  //   if (localStorage.hasOwnProperty("pagesTourSeen")) {
  //     const restProps = JSON.parse(localStorage.getItem('pagesTourSeen'))
  //     localStorage.setItem('pagesTourSeen', JSON.stringify({
  //       ...restProps,
  //       lost_pass: true,
  //     }))
  //   }else {
  //     localStorage.setItem('pagesTourSeen', JSON.stringify({
  //         lost_pass: true,
  //     })) 
  //   }
  // }


  async function handleSubmit(data, { reset }) {
    // handleFinishTour()
    setLoading(true)
    
    try {
      // Remove all previous errors
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string()
          .email("Digite um e-mail válido.")
          .required("Digite seu e-mail de cadastro."),
        cpf: Yup.string()
          .test("is-cpf", "CPF inválido", (value) => validateCPF(value))
          .required("Digite seu CPF."),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      
      // Validation passed
      await recovery({
        email: data.email,
        cpf: data.cpf,
      });

      // Loading off
      setLoading(false);

    } catch (err) {
      /* const validationErrors = {}; */
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
      // Loader off
      setLoading(false);
    }
  }

  // useEffect(() => {
  //    // Tour controller
  //    if (localStorage.hasOwnProperty("pagesTourSeen")){
  //     if(!JSON.parse(localStorage.getItem("pagesTourSeen")).lost_pass) {
  //       tour.start()
  //     }
  //   }
  // },[])

  return (
    <div className="container-principal-LostPass">
      <div className="container-LostPass">
        <div className="container-LostPass">
          <ToastContainer />
         {/* <ButtonVoltar onClick={handleFinishTour} to={links.home}/> */}
         <ButtonVoltar to={links.home}/>
          <div className="container-form">            
            <div className="column-LostPass">
              <Form id="myform" ref={formRef} onSubmit={handleSubmit}>
                <Link to="/" className="bt-top-back"></Link>
                <h1>Recuperação de senha</h1>
                <p className="container-description">
                  Informe seu e-mail de cadastro abaixo para receber o link de
                  recuperação de senha.
                </p>
                <div  className="inputField tour-lostpass-email">
                  <InputForm name="email" type="email" placeholder="E-mail" />
                </div>
                <div  className="inputField tour-lostpass-cpf">
                  <InputMask
                    name="cpf"
                    mask="999.999.999-99"
                    maskChar=" "
                    type="cpf"
                    placeholder="CPF"
                  />
                </div>
                </Form>
              </div>
              <div className="column detail-LostPass">
                <img src={pageImage} alt="Recuperação de senha" />
              </div>
          </div>
        </div>
      </div>
      <div className="container-inferior-LostPass ">
          <div className="container-AccountScreen tour-lostpass-finish">
            <form>
              <ButtonAvancar className={"container-AccountScreen-button"} Form="myform" type="submit">
                Enviar
              </ButtonAvancar>
            </form>
          </div>
      </div>
    </div>
  );
}

export default withRouter(RecoveryPass);
