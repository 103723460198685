import { useRef, useContext, useEffect, useState } from "react";
import { Form } from "@unform/web";
import { Context } from "../../Context/ContextGlobal";
import * as Yup from "yup";
import { validateCPF } from "validations-br";
import Auth from "../../Api/Auth";
import { useAlert } from "react-alert";

// Data analysis
import { MP } from "../../components/MixPanel";

// history hook import
import { useHistory } from "react-router-dom";

//axios instance
import axios from "axios";

// Local components
import { useLoading } from "../../components/loading";
import ButtonPadrao from "../../components/ButtonPadrao";
import NavBar from "../../components/NavBar";
import ButtonVoltar from "../../components/ButtonVoltar";
import adicionar from "../../assets/productsAdd/adiciona.svg";
import iconCheck from "../../assets/Main/icon-check-white.svg";

// Assets
import InputForm from "../../components/InputForm";
import InputMask from "../../components/InputMask";
import perfilUsuario from "../../assets/userData/user.svg";
import camera from '../../assets/AccountScreen/photo-camera-branco.svg'

// Css
import "./UserData.css";
// links
import links from "../../config/Links";

// Toaster
import { errorToaster, successToaster, ToastContainer, warningToaster } from "../../components/Toasters";

//Module responsible to create a htmlCanvas and crop the img
import ImageCropper from "../../components/ImageCropper";

// Tour import
import { ShepherdTourContext } from "react-shepherd";
import ModalBase from "../../components/ModalBase";
import { userData } from "../../components/Tour/Steps/UserData";
import { initialValidatePhoneInput, validatePhoneInput } from "../../Helper/Utils";
import InputMaskPhone from "../../components/InputMaskPhone";

// Main function
export default function UserData() {

  // MP
  MP("User Data");

  const DEBUG = false;

  // Tour context
  const tour = useContext(ShepherdTourContext)
  // History hock
  const history = useHistory();

  // FormValues Util State
  const [userId, setUserId] = useState("")
  const [userRequestData, setUserRequestData] = useState({
    id: "",
    name: "",
    cpf: "",
    email: "",
    cellphone: "",
    password: "",
    confirm_password: ""
  })
  // const [name, setName] = useState("")
  // const [cpf, setCpf] = useState("")
  // const [email, setEmail] = useState("")
  // const [cellphone, setCellphone] = useState("")
  // const [password, setPassword] = useState("")
  const [urlProfile, setUrlProfile] = useState(perfilUsuario)
  const [showTutorialModal, setShowTutorialModal] = useState(false)

  const [timeToCrop, SetTimeToCrop] = useState(false)
  const [isProfilePhotoEdit, setIsProfilePhotoEdit] = useState(false)
  const [firstImage, setFirstImage] = useState(true)

  // cropping Image
  const [imageToCrop, setImageToCrop] = useState(undefined);
  const [croppedImage, setCroppedImage] = useState("no_image_url");

  // Form ref
  const formRef = useRef(null);

  // Alert
  const alert = useAlert();

  // Loading
  const { setLoading } = useLoading();

  // User context
  const { user } = useContext(Context);
  const handleStateUserCallback =(response) => {

    // spread user data from the state

    const {
      id,
      name,
      cpf,
      email,
      cellphone,
      avatar_url,

    } = response

   
    const cellphoneMod = initialValidatePhoneInput(cellphone)
    
    setUserRequestData({
      ...userRequestData,
      id,
      name,
      cpf,
      email,
      avatar_url: response.avatar_url !== undefined ? response.avatar_url + "?nocache=" + (new Date).getTime().toString() : perfilUsuario,
      cellphone: cellphoneMod,
    })
    callbackGetUrlProfile(response.avatar_url !== undefined ? response.avatar_url + "?nocache=" + (new Date).getTime().toString() : perfilUsuario)
  }
  const callbackGetUrlProfile = (imageUrl) => {
    setUrlProfile(imageUrl)
  }

  const onUploadFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener('load', () =>
        setImageToCrop(reader.result)
      );
      const image = reader.readAsDataURL(event.target.files[0]);
      if(DEBUG)console.log("IMAGE CROP_URL",image )
      setIsProfilePhotoEdit(true)
      SetTimeToCrop(true)
      // Changing for a descritive modal 
      if(firstImage) {
        setShowTutorialModal(!showTutorialModal)
        setFirstImage(false)
      }
    }
  };
  
  useEffect(() => {
     // Tour controller
     if (localStorage.hasOwnProperty("pagesTourSeen")){
      if(!JSON.parse(localStorage.getItem("pagesTourSeen")).user_data) {
        tour.start()
      }
    }else {
      tour.start()
      handleFinishTour()
    }

    // Loading
    setLoading(true);

    // Auth API
    const auth = new Auth();

    // Exec request
    auth.me()
      .then((response) => {
        // Setter State
        handleStateUserCallback(response)
      })
      .catch((error) => {
        setLoading(false)
        // console.log("Error", error);
        // User message
        alert.removeAll();
        // alert.info(
        //   "Ocorreu um erro ao acessar seus dados cadastrais. Se o problema persistir entre em contato com nosso time de suporte.",
        //   {
        //     title: "e-Carreto",
        //     closeCopy: "Ok"
        //   }
        // );
        errorToaster( "Ocorreu um erro ao acessar seus dados cadastrais. Se o problema persistir entre em contato com nosso time de suporte.",)
      }).finally(() => {
        // sets the loading to false
        setLoading(false);

      });
      

  }, [ user]);

  // Top page
  useEffect(() => {
    try { window.scrollTo(0, 0); } catch (err) {}
  }, []);  

  //Transforming the tag into a file 
  const myFile = new File([croppedImage], "image.jpeg", {
    type: croppedImage.type,
  });

  function handleFinishTour() {
     // Tour controller
     if (localStorage.hasOwnProperty("pagesTourSeen")){
      const restProps = JSON.parse(localStorage.getItem('pagesTourSeen'))
      localStorage.setItem('pagesTourSeen', JSON.stringify({
        ...restProps,
        user_data: true,
      }))
      
    }else {
      localStorage.setItem('pagesTourSeen', JSON.stringify({
        user_data: true,
      }))
    }
  }

  async function handleSubmit(e) {
    // Finishes tour if its on
    handleFinishTour()
    
    e.preventDefault()
    setLoading(true)

    try {
      // spread userData
      const {
        id,
        name,
        cpf,
        email,
        cellphone,
        url_profile,
        password,
        confirm_password,
      } = userRequestData

      // Gets the phone input
      const cellphoneInputValue = document.getElementById('user-data-cellphone').value
          
      // Testing the if the token is being called
      const dadosUsuario = JSON.parse(localStorage.getItem("token"));
      const { token } = dadosUsuario
      if(DEBUG)console.log(token)

      // Soft Validations
      const validation = name !== "" && token
        && password !== "" && email !== "" && cpf !== "" && cellphone
      
      // Hard Regex validations
      if(!validateCPF(cpf)){
        errorToaster("CPF inválido")
        setLoading(false)
        return
      }
      if (!name.match(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi)) {
        errorToaster('Nome deve conter apenas letras.')
        setLoading(false)
        return
      }
      if(!name.match(/^\s*[\S]+(\s[\S]+)+\s*$/gms)) {
        errorToaster('Informe o nome completo.')
        setLoading(false)
        return
      }

      if(cellphoneInputValue.length < 14) {
        errorToaster('Informe o telefone completo.')
        setLoading(false)
        return
      }
      
      if (validation) {

        try {
          const form = new FormData();
          form.append("id", id);
          form.append("name", name);
          form.append("email", email);
          form.append("password", password);
          form.append("cpf", cpf);
          form.append("cellphone", cellphoneInputValue);
          form.append("avatar_file", croppedImage !== "no_image_url" ? croppedImage : "");

          // Form test logger
          if (DEBUG) {
            for (var pair of form.entries()) {
              console.log(pair[0] + ', ' + pair[1]);
            }
          }

          const options = {
            method: 'PATCH',
            url: 'https://api.ecarreto.com.br/user/update/',
            headers: {
              'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
              'x-access-token': token
            },
            data: form
          };
          // password validation
          if(password !== confirm_password){
            setLoading(false)
            warningToaster("As senhas devem ser exatamente iguais")
            return
          }

          axios.request(options).then(function (response) {
            setLoading(false)
            if(DEBUG) console.log(response.data);

          }).catch(function (error) {
            console.error(error);
          });
        } catch (err) {
          console.warn(err)
        }
        successToaster("Alterações realizadas, redirecionando para a página principal", pushesToMain())

        function pushesToMain() {
          setTimeout(function () {
            history.push({
              pathname: links.main,  
            });   
          }, 4000)
        }

      } else {
        errorToaster("Revise os campos e tente novamente")
        setLoading(false)
        return
      }

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
    }
    setLoading(false)
  }
  return (
    <div className="container-principal">
      {showTutorialModal && <ModalBase
        customMessage={"Como editar: clique e arraste sobre a área da foto selecionada e em seguida clique em confirmar foto para recortar a imagem."}
        confirmMessage={"Entendi, quero tentar"}
        setOpenModal={setShowTutorialModal}
        customFunction={() => { }}
      />}
      <NavBar />
      <ToastContainer />
      <div className="container">
        <div className="container-UserData">
          <div className="imgVoltar-UserData">
            <ButtonVoltar onClick={handleFinishTour} to={links.main} />
            <h1>Dados Cadastrais</h1>
          </div>
          <div className="container-form-UserData ">
            <div
              className="posicao-img-user tour-userdata-page-profile-photo "
              htmlFor="image"
              style={{ display: "flex", justifyContent:"center", flexDirection: "column" }}
            >

              {urlProfile && !isProfilePhotoEdit &&
                <div className="perfil-menu-modal">
                  <img className="profile-image-menu-modal" alt={"perfil"} src={urlProfile || perfilUsuario} />
                </div>
              }
              {isProfilePhotoEdit &&
                <ImageCropper
                  imageToCrop={imageToCrop}
                  setResultValue={setCroppedImage}
                  timeToCrop={timeToCrop}
                  setUrlProfile={setUrlProfile}
                  setIsProfilePhotoEdit={setIsProfilePhotoEdit}
                />
              }


            {croppedImage !== "no_image_url" &&
                <ButtonPadrao style={{ marginTop: "30px" }}>
                  <img
                    style={{
                      width: "40px",
                      marginRight: "10px",
                      marginBottom: "10px"
                    }}
                    src={camera}
                  />
                  <label >
                    <input
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={onUploadFile}
                      type="file"

                    ></input>
                    {"Carregar outra"}
                  </label>
                </ButtonPadrao>
              }
              {croppedImage === "no_image_url" &&
                <div className="button-upload-userData">
                  <ButtonPadrao style={{ marginTop: "50px" }}>
                    <img
                      style={{
                        width: "40px",
                        marginRight: "10px",
                        marginBottom: "10px"
                      }}
                      src={isProfilePhotoEdit ? adicionar : camera}
                    />
                    <label >
                      <input
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={onUploadFile}
                        type="file"

                      ></input>
                      {isProfilePhotoEdit ? "Carregar nova" : "Adicionar foto"}
                    </label>
                  </ButtonPadrao>
                </div>
              }

              {croppedImage !== "no_image_url" &&
                <ButtonPadrao onClick={() => setIsProfilePhotoEdit(!isProfilePhotoEdit)} style={{ marginTop: "30px" }}>
                  <img
                    style={{
                      width: "40px",
                      marginRight: "10px",
                      marginBottom: "10px"
                    }}
                    src={isProfilePhotoEdit ? iconCheck : adicionar}
                  />

                  {isProfilePhotoEdit ? "Confirmar foto" : "Editar foto"}

                </ButtonPadrao>
              }
            </div>
            <div className="formulario-userData tour-userdata-page-profile-fields">
              <Form
                className="form-userData"
                id="Update"
                ref={formRef}
                // onSubmit={handleSubmit}
              >

                <div className="inputField">
                  <InputForm
                    style={{ height: "50px", padding: "15px" }}
                    name="name"
                    type="name"
                    value={userRequestData.name}
                    placeholder="Nome completo"
                    onChange={(e) => setUserRequestData(prevVal => ({ ...prevVal, name: e.target.value }))}
                  />
                </div>

                <div className="inputField">
                  <InputMask
                    style={{ height: "50px", padding: "15px" }}
                    name="cpf"
                    mask="999.999.999-99"
                    maskChar=" "
                    type="cpf"
                    value={userRequestData.cpf}
                    onChange={(e) => setUserRequestData(prevVal => ({ ...prevVal, cpf: e.target.value }))}

                    placeholder="CPF"
                  />
                </div>
                <div className="inputField">
                  {/* <InputMask
                    style={{ height: "50px", padding: "15px" }}
                    type="cellphone"
                    name="cellphone"
                    mask={userRequestData.cellphone.toString().length > 10 ? "(99) 99999-9999" : "(99) 9999-9999"}
                    value={userRequestData.cellphone}
                    onChange={(e) => setUserRequestData(prevVal => ({ ...prevVal, cellphone: e.target.value }))}
                    maskChar=" "
                    placeholder="Celular"
                  /> */}
                  <InputMaskPhone
                    name={"user-data-cellphone"}
                    id="user-data-cellphone"
                    placeholder="Celular"
                    defaultValueProp={userRequestData.cellphone }
                  />
                </div>

                <div className="inputField">
                  <InputForm
                    style={{ height: "50px", padding: "15px" }}
                    name="email"
                    type="email"
                    value={userRequestData.email}
                    onChange={(e) => setUserRequestData(prevVal => ({ ...prevVal, email: e.target.value }))}
                    placeholder="E-mail"
                  />
                </div>
                <div className="inputField">
                  <InputForm
                    style={{ height: "50px", padding: "15px" }}
                    name="Nova senha"
                    type="password"
                    onPaste={e => { e.preventDefault(); return false }}
                    onDrop={e => { e.preventDefault(); return false }}
                    autoComplete={e => { e.preventDefault(); return false }}
                    value={userRequestData.password}
                    onChange={(e) => setUserRequestData(prevVal => ({ ...prevVal, password: e.target.value }))}

                    placeholder="Nova Senha"
                  />
                </div>

                <div className="inputField">
                  <InputForm
                    style={{ height: "50px", padding: "15px" }}
                    name="Confirmar nova senha"
                    onPaste={e => { e.preventDefault(); return false }}
                    onDrop={e => { e.preventDefault(); return false }}
                    autoComplete={e => { e.preventDefault(); return false }}
                    type="password"
                    value={userRequestData.confirm_password}
                    onChange={(e) => setUserRequestData(prevVal => ({ ...prevVal, confirm_password: e.target.value }))}

                    placeholder="Confirmar nova senha"
                  />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <div className="container-inferior-cinza">
        <div className="container">
          <div className="container-UserData">
            <div className="container-UserData-inferior">
              <form>
                <ButtonPadrao className="tour-userdata-page-finish" onClick={(e) => handleSubmit(e)} >
                  Salvar informações
                </ButtonPadrao>
              </form>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
