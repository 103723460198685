import { Component } from "react";
import "./ButtonCriarConta.css";
import criarConta from "../../src/assets/home/icon-email.svg";

class ButtonCriarConta extends Component {
  render() {
    const { props } = this;

    const defaultProps = {
      ...props,
      className:
        "btnCriarConta btnHome " + ( props.className || "" ),
    };

    return (
      <button {...defaultProps}>
        <div className="imgBtn">
          <img alt="Cadastre-se" className="criarConta " src={criarConta} />
        </div>
        {props.children}
      </button>
    );
  }
}

export default ButtonCriarConta;
