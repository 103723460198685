import { Link } from "react-router-dom";

// Data analysis
import { MP } from "../../components/MixPanel";

// Local components
import NavBar from "../../components/NavBar";
import ButtonVoltar from "../../components/ButtonVoltar";

// Css
import "./HelpPage.css";

// Assets
import chat from "../../assets/Main/icon-chat.svg";

// Routes
import links from "../../config/Links";
import AccordionHelpPage from "../../components/AccordionHelpPage";

export default function HelpPage() {

  // MP
  MP("Help");

  return (
    <div className="container-principal">
      <NavBar />
      <ButtonVoltar to={links.main}/>
      <div className="container">
        <div className="container-HelpPage">
          <div className="container-superior-HelpPage">
            <div>
              <h1>Ajuda</h1>
            </div>
            <div>
              <Link style={{ textDecoration: "none" }} to={links.chat}>
                <button className="btn-ajuda-HelpPage">
                  <img alt="chat" src={chat} />
                  <p>Solicite ajuda agora</p>
                </button>
              </Link>
            </div>
          </div>
          <h2>
            Como faço para cadastrar um novo meio
            <br />
            de pagamento
          </h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
            hendrerit nulla ut nisl malesuada, et aliquet augue commodo.
            Praesent tempus mollis nisi, nec sagittis nulla efficitur ac. Nulla
            sagittis massa lectus, quis iaculis nibh rhoncus id. Nunc eu auctor
            dui. Duis euismod est ac volutpat consectetur. Duis tristique, eros
            viverra varius scelerisque, lorem orci lacinia orci, ut vulputate
            velit nisi in est. Sed rhoncus erat non vulputate faucibus. Cras
            imperdiet porta ipsum vitae venenatis. Nulla orci sem, sodales at
            turpis nec, interdum pellentesque enim. Quisque fringilla lobortis
            lorem. Phasellus gravida purus eget metus sodales iaculis. Nam
            rutrum, leo a malesuada ornare, odio est molestie tellus, at iaculis
            felis libero vel felis. Vivamus eget lectus nec massa pharetra
            eleifend.
          </p>
          <AccordionHelpPage
            title="Minha solicitação de transporte está em atraso, o que devo fazer?"
            content="<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <p>rem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ididunt ut labore et dolore magna aliqua.</p>"
          />
          <AccordionHelpPage
            title="Como proceder para realizar o cancelamento de solicitação de transporte?"
            content="<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam hendrerit nulla ut nisl malesuada, et aliquet augue commodo. Praesent tempus mollis nisi, nec sagittis nulla efficitur ac. Nulla sagittis massa lectus, quis iaculis nibh rhoncus id. Nunc eu auctor dui. Duis euismod est ac volutpat consectetur. Duis tristique, eros viverra varius scelerisque, lorem orci lacinia orci, ut vulputate velit nisi in est. Sed rhoncus erat non vulputate faucibus. Cras imperdiet porta ipsum vitae venenatis. Nulla orci sem, sodales at turpis nec, interdum pellentesque enim. Quisque fringilla lobortis lorem. Phasellus gravida purus eget metus sodales iaculis. Nam rutrum, leo a malesuada ornare, odio est molestie tellus, at iaculis felis libero vel felis. Vivamus eget lectus nec massa pharetra eleifend.</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <p>rem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ididunt ut labore et dolore magna aliqua.</p>"
          />
        </div>
      </div>
    </div>
  );
}
