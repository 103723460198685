import { Component } from "react";
import "./Input.css";

class Input extends Component {
  render() {
    const { props } = this;
    const defaultProps = {
      ...props,
    };

    let container = " input " + (props.containe ? props.container : "");

    return (
      <div className={container}>
        <div style={{ width: "100%" }}>
          <input {...defaultProps} />
        </div>
        <div>
          <img alt="" src={props.img} />
        </div>
      </div>
    );
  }
}

export default Input;
