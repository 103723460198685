import { createContext, useContext, useState } from "react";

// CSS
import "./style.css";

// Load icons
import iconLoadBlack from "../../assets/Global/icon-loading-black.svg";

// Loader context
const LoadingContext = createContext({
  loading: false,
  setLoading: null,
});

// Loader provider
export function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const value = { loading, setLoading };

  // Add body class
  const bodyElement = document.getElementsByTagName("body")[0];
  bodyElement.className = "loading";

  return (
    <LoadingContext.Provider value={value}>
        {children}
        {loading &&
        <div className="Loading">
            <div className="loadingWrapper">
                <div className="loadingIcon">
                    <img src={iconLoadBlack} alt="" />
                </div>
                <div className="loadingMsg">Por favor aguarde...</div>
            </div>
        </div>        
        }
    </LoadingContext.Provider>
  );
}

// Loader hook
export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within LoadingProvider");
  }
  return context;
}
