import React, { useState, useEffect, useContext } from "react";
import { Form } from "@unform/web";
import InputMask from "../../components/InputMask";
import { withRouter } from "react-router";
import { Link, useHistory, useLocation} from "react-router-dom";

// Data analysis
import { MP } from "../../components/MixPanel";

// Routes
import links from "../../config/Links";
import Cards from 'react-credit-cards'

// Css
import 'react-credit-cards/es/styles-compiled.css';
import "../OrderContact/OrderContact.css";
import "../../components/InputForm.css";

// Assets
import ambienteSeguro from "../../assets/orderResume/ambiente-seguro.svg";
import transporteSegurado from "../../assets/map/transporteSegurado.svg";
import cartao from "../../assets/orderContact/cartao.svg";
import wait from "../../assets/Global/icon-loading-white.svg";
import caminhao from "../../assets/SearchingDriver/caminhao.svg";

// Local components
import NavBar from "../../components/NavBar";
import InputForm from "../../components/InputForm";
import ButtonPadrao from "../../components/ButtonPadrao";
import Switch from "../../components/Switch";
import ButtonVoltar from "../../components/ButtonVoltar";
import HelpButton from "../../components/ButtonHelp";
import { useLoading } from "../../components/loading";
import ModalPayment from "../../components/ModalPayment";
import Auth from "../../Api/Auth";
import {warningToaster, errorToaster,ToastContainer, successToaster } from "../../components/Toasters";
import { mountPayload, shopcartCheck, isExpiryDateValid, handleValidateExpiryInput, debugAlert, shopcartClean } from "../../Helper/Utils";
import { ShepherdTourContext } from "react-shepherd";


import Order from "../../Api/Order";
 function OrderContact(props) {

  const tour  = useContext(ShepherdTourContext)

  const DEBUG = false;

  // MP
  MP("Order Contact");

  // Direct from order page repayment
  const location = useLocation();
  let repay = false
  if(location.state?.repay) repay = true;

  //  const direclyOrderRepay = repay;
  if(DEBUG)console.log("Order to be repayed", repay)
   
  const history = useHistory();

  const [toggle, setToggle] = useState(false);
  const [toggle1, setToggle1] = useState(true);
  const [salvar, setSalvar] = useState(false);

  const [userData, setUserData] = useState({})

  const [nameFrom, setNameFrom] = useState("")
  const [cpfFrom, setCpfFrom] = useState("")
  const [cellphoneFrom, setCellphoneFrom] = useState("")
  const [emailFrom, setEmailFrom] = useState("")

  const [nameTo, setNameTo] = useState("")
  const [cpfTo, setCpfTo] = useState("")
  const [cellphoneTo, setCellphoneTo] = useState("")
  const [emailTo, setEmailTo] = useState("")

  // credit card states utils
  const [cvc, setCvc] = useState("");
  const [expiry, setExpiry] = useState("");
  const [focus, setFocus] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [price, setPrice] = useState("");
  const [paymentType, setPaymentType] = useState("credit");
  const [isSendingOrder, setIsSendingOrder] = useState(false);

 // QRCode payment
  const [paymentQRCode, setPaymentQRCode] = useState("");   
  const [paymentQRCodeTxt, setPaymentQRCodeTxt] = useState("");   

  //order states: 
  const [apiResponse, setApiResponse] = useState({ order: { id: "...", serial: "..." } })

  // Controlling if payment order has failed for the very first time
  const [orderPaymentHasFailed, setOrderPaymentHasFailed] = useState(repay)

  // Modal state
  const [modalOpen, setModalOpen] = useState(false);

  // Goto fo driver search
  const [driverSearchOn, setDriverSearchOn] = useState(false);  

  // Loading Context
  const { setLoading } = useLoading();

  // error input states utils
  const [errorInput, setErrorInput] = useState({
    nameFrom: false,
    cpfFrom: false,
    emailFrom: false,
    cellphoneFrom: false,

    nameTo: false,
    cpfTo: false,
    emailTo: false,
    cellphoneTo: false,

    name: false,
    expiry: false,
    number: false,
    cvc: false,
  })

  const handleDeleteDataToggle = () => {
    setNameFrom("")
    setCpfFrom("")
    setEmailFrom("")
    setCellphoneFrom("")
  }

  const handleDeleteDataToggle1 = () => {
    setNameTo("")
    setCpfTo("")
    setEmailTo("")
    setCellphoneTo("")
  }
  function handleResetInputError () {
        // once is everything validated
        setErrorInput({
          nameFrom: false,
          cpfFrom: false,
          emailFrom: false,
          cellphoneFrom: false,
          nameTo: false,
          cpfTo: false,
          emailTo: false,
          cellphoneTo: false,
        })
  }

  // Copying data to clipboard
  const copyTxt = ( e ) => {
    const field = e.target.value;
    // Copy text
    navigator.clipboard.writeText(field).then(function() {
      // console.log('Copying to clipboard was successful!');
      // Mark input border as green to show copied status
      // e.target.style.borderColor = "#28a74580";
      successToaster("PIX copiado com sucesso! Abra seu app bancário, escolha pagamento via Pix Copia e Cola e cole o código copiado.")
    }, function(err) {
      // console.error('Could not copy text: ', err);
    });
  }

  // Redir to queue
  function sendToQueue(){

    debugAlert("[DEBUG] - Entrando no função sendToQueue", userData )
    // if(DEBUG)warningToaster("Entrando na função de fila final")


    // Start loading
    // setLoading(true);

    if (DEBUG) console.log("sendToQueue", new Date());
    // setLoading(true);
    try{
      // LocalStorage will cleaned here
      if (DEBUG) console.log("sendToQueue : delete storage", new Date());
      shopcartClean()
    } catch ( err ){ 
      // Ignore delete storage errors ( secondary )
      if (DEBUG) console.log("sendToQueue : delete storage error", new Date());
      console.warn("Error on delete storage data: ", err);
    }
    debugAlert("[DEBUG] - Mudando o estado de driverSearchOn", userData )
    setDriverSearchOn(true);
  }

  // User trying until the order send is running ( users never wait )
  async function _handleSaveDataStorageTry(){
    // Nothing to do
    warningToaster("Por favor aguarde, seu pedido está sendo processado !");	
  }

  function handleFinishTour() {
    // Tour controller
    if (localStorage.hasOwnProperty("pagesTourSeen")){
      const restProps = JSON.parse(localStorage.getItem('pagesTourSeen'))
      localStorage.setItem('pagesTourSeen', JSON.stringify({
        ...restProps,
      order_contact: true,
    }))
  }else {
    localStorage.setItem('pagesTourSeen', JSON.stringify({
      order_contact: true,
    }))
  }
}
async function _handleSaveDataStorage() {
  
      // Tour controller
      handleFinishTour()
      // Date final validation
      if (localStorage.hasOwnProperty("dte")) {

        const deliveryDate = new Date((localStorage.getItem("dte")))
        const selectedData = new Date(deliveryDate)
        const now = new Date()
        if (selectedData < now) {
          // if the date is older than now gives a new oportunity to set the correct data
          history.push('/principal')
          return 
        }
      } else {
        // Must never take place, but if so, show:
        errorToaster("Por favor, defina uma data!");
        return
      }


    // if(DEBUG)warningToaster("Entrando na função save do button")
    // validation personal data form - isolate
    if (nameFrom === "") {
      errorToaster("Preencha o campo nome da coleta!")
      setErrorInput({ ...errorInput, nameFrom: true })
      return
    } else if (cpfFrom === "") {
      errorToaster("Preencha o campo cpf de coleta!")
      setErrorInput({ ...errorInput, cpfFrom: true })
      return
    } else if (cellphoneFrom === "") {
      errorToaster("Preencha o campo telefone de coleta!")
      setErrorInput({ ...errorInput, cellphoneFrom: true })
      return
    } else if (emailFrom === "") {
      errorToaster("Preencha o campo email de coleta!")
      setErrorInput({ ...errorInput, emailFrom: true })
      return
    } else if (nameTo === "") {
      errorToaster("Preencha o campo nome de entrega!")
      setErrorInput({ ...errorInput, nameTo: true })
      return
    } else if (cpfTo === "") {
      errorToaster("Preencha o campo cpf de entrega!")
      setErrorInput({ ...errorInput, cpfTo: true })
      return
    } else if (cellphoneTo === "") {
      errorToaster("Preencha o campo telefone de entrega!")
      setErrorInput({ ...errorInput, cellphoneTo: true })
      return
    } else if (emailTo === "") {
      errorToaster("Preencha o campo email de entrega!")
      setErrorInput({ ...errorInput, emailTo: true })
      return
    }


    // Payment object
    let payment = {};

    // debit/credit card validations 
    if( paymentType !== "pix" ) {

      if (number === "") {
        errorToaster("Revise o campo número do cartão!")
        setErrorInput({ ...errorInput, number: true })
        return
      }
      else if (name === "") {
        errorToaster("Revise o campo nome do cartão!")
        setErrorInput({ ...errorInput, name: true })
        return
      }else if (expiry === "" || !isExpiryDateValid(expiry) ) {
        errorToaster("Revise o campo validade do cartão!")
        return
      } else if (cvc === "" || cvc.length < 3) {
        errorToaster("Revise o código de segurança do cartão!")
        return
      }
    
      // End of validation. Start transaction
      // setLoading(true);

      // Check if expiration date/year have 4 digits
      let expirationDate = expiry;
      let dateExpiration = expiry.split("/");
      // Check if full date was passed
      if ( dateExpiration.length === 2 ) {
        // Year
        let year = dateExpiration[1];
        // Check if the date year have less than 2
        if ( year.length <= 2) {
          // Add 20 to the beginning of the date
          year = "20" + year;

          // Passing the correct date do endpoint
          expirationDate = dateExpiration[0] + "/" + year;

          // Return final full date
          setExpiry(dateExpiration[0] + "/" + year);        
        }   
      } 

      // Payload payment
      payment = {
        // payment payload
        type: paymentType,
        card_number: number,
        card_holder: name,
        card_security_code: cvc,
        card_expiration: expirationDate,
        installments: 1,
        save: !salvar
      }      
            
    } else {
      // PIX
      payment = {
        // payment payload
        type: paymentType
      }
    }

    // MP
    MP("Payment", { "type": paymentType } );    

    // Reseting input error 
    handleResetInputError()

    /**Mounting payloads */
    let to = {
      name: nameTo,
      cpf: cpfTo,
      email: emailTo,
      cellphone: cellphoneTo
    }
    let from = {
      name: nameFrom,
      cpf: cpfFrom,
      email: emailFrom,
      cellphone: cellphoneFrom
    }

    localStorage.setItem("contactDataTo", JSON.stringify(to));
    localStorage.setItem("contactDataFrom", JSON.stringify(from));

    //then pushing into search driver page

    //passing by shopcart validation
    if (!shopcartCheck()) {

      // MP
      MP("Payment", { "error": "true" } );  
            
      errorToaster("Houve um erro na validação do carrinho. Tente novamente e se o problema persistir, entre em contato com nossa equipe de suporte.")
      return;
    }
    
    try {

      setIsSendingOrder(true);

      let payload = await mountPayload(payment) //await until payload is mounted, don't remove it
      if (DEBUG) console.log("PAYLOAD", payload);

      debugAlert("[DEBUG] - Payload montado", userData )
      
      // dividing payload before request is called
      let products = payload.products
      delete payload.products
      delete payload.payment
      let newPayload = { ...payload, payment, products };
      
      if (DEBUG) console.log("PAYLOAD + PAYMENT", newPayload);

      // if(DEBUG)warningToaster("Passou pela criação do payload")
      const order = new Order();

      if (DEBUG) console.log("orderPaymentHasFailed", orderPaymentHasFailed);

      if ( !orderPaymentHasFailed ) {
        debugAlert("[DEBUG] - Entrando no call de order/add", userData )
        debugAlert(`[DEBUG] - Tipo de pagamento: ${ newPayload.payment.type} definido`, userData )
        
        // MP
        MP("Payment", { "success": "true" } );  

        await order.orderPost("POST", "order/add/", newPayload)
        .then((response) => {
          debugAlert(`[DEBUG] - Status do pagamento de pagamento : ${response.financial.payment.status || "ERRO AO DEFINIR"} definido`, userData )
          // if(DEBUG)warningToaster("Entrando na função de pagamento")
            
            if ( newPayload.payment.type === "debit" ) {

              // Return to Debit
              // Logging the url payment link
              if (DEBUG) console.log("LINK DE PAGAMENTO 1", response.financial?.payment?.url || "Não deu certo")
              // Setting payment URL to be used into the modal
              localStorage.setItem("pLin", JSON.stringify(response.financial?.payment?.url));
              // Show modal
              setModalOpen(true);
              setLoading(false);
              // redirects
              window.open(response.financial?.payment?.url);
            } else if ( newPayload.payment.type === "pix" ) {
              debugAlert("[DEBUG] - Tipo de pagamento: PIX definido", userData )

              // Return to Pix
              if (DEBUG) console.log("PIX QRcode", response.financial?.payment?.qrcode || "Não deu certo");
              if (DEBUG) console.log("PIX QRcode Text", response.financial?.payment?.qrcode_txt || "Não deu certo");
              setLoading(false);
              // Set QRCode base64
              setPaymentQRCode(response.financial?.payment?.qrcode);
              // Set QRCode text
              setPaymentQRCodeTxt(response.financial?.payment?.qrcode_txt);
            }
            setApiResponse(response);

            // Making sure I can use the order id later without making another request
            localStorage.setItem("lastestOrderNumber", JSON.stringify(response.order.id));

            // if(DEBUG)warningToaster("Entrando função de ir para o searchDriver")
            handleGoToDriverSearch(response);

            setLoading(false);
            // Return
            return response;
          })
          .catch((err) => {
            // Error

            setIsSendingOrder(false);

            console.log("Teste erro ", err?.response?.data);
            
            
            if( err?.response?.data?.error ){
              // Know error
              const { message_br, message } = err.response.data;
              debugAlert(`[DEBUG] - ERRO ao criar o pedido - DETALHES:  ${message_br || message}`, userData )
              errorToaster( message_br || message );
              setLoading(false);
              return false;
            } else {
              // Unknown error
              // errorToaster("Ocorreu um erro ao registar seu pedido. Tente novamente e se o problema persistir, entre em contato com noss equipe de suporte.");
              // setLoading(false);
            }
            
            // Return
            return false;
          });

      } else if (orderPaymentHasFailed) {
        debugAlert("[DEBUG] - O pagamento do pedido falhou, chamando re-pay", userData )

        // MP
        MP("Payment", { "success": "false" } );  

        // new attempet to pay the order

        // Tests if date is valid
        if (localStorage.hasOwnProperty("dte")) {
          let storedDate = new Date((localStorage.getItem("dte")))
          let now = new Date()
          if (storedDate < now) {
            errorToaster("Por favor, defina uma data maior que a atual!",() => history.push(links.main));
            return;
          }
        }else {
          return 
        }

        // errorToaster("Ocorreu um erro ao registar seu pedido. Tente novamente e se o problema persistir, entre em contato com noss equipe de suporte.");

        if ( newPayload.payment.type === "debit" ) {
          // open modal - waiting for payment 
          setModalOpen(true)
          let url;
          if ( localStorage.hasOwnProperty("pLin") ) {
            url = JSON.parse(localStorage.getItem("pLin"));
          }
          // redirects
          window.open(url);
          setLoading(false);
          return;
        }
        // payload payment on retrial
        let newpaymentPayload = {};

        if( paymentType !== "pix" ){
            newpaymentPayload = {
              payment: {
                id: JSON.parse(localStorage.getItem("lastestOrderNumber")),
                type: payment.type,
                // card_brand: payment.card_brand,
                card_number: payment.card_number,
                card_holder: payment.card_holder,
                card_expiration: payment.card_expiration,
                card_security_code: payment.card_security_code,
                installments: payment.installments,
                save: payment.save
              }
            }
        } else {
          // PIX
          newpaymentPayload = {
            payment: {
              id: JSON.parse(localStorage.getItem("lastestOrderNumber")),
              type: payment.type,
            }
          }          
        }

        if(DEBUG) console.log(newpaymentPayload);
        debugAlert("[DEBUG] - Chamando integração de order/pay, visando pagamento", userData )

        await order.orderPost("POST", "order/pay/", newpaymentPayload).then((responsePayment) => {
          debugAlert(`[DEBUG] - Tipo de pagamento em RE-PAY: ${newpaymentPayload.payment.type || "ERRO AO DEFINIR"} definido`, userData )
          // 
          if(DEBUG) console.log("RESPOSTA RE-PAY", responsePayment)

          // Alert on payment fail
          if( responsePayment.error ) {
            debugAlert(`[DEBUG] - Entrando no ERROR de re-pay, parando o loading e emitindo erros"} `, userData )
            debugAlert(`[DEBUG] - Valor de responsePayment.error em re-pay: ${responsePayment.error || "ERRO AO DEFINIR"} `, userData )
            debugAlert(`[DEBUG] - Tipo do responsePayment.error em re-pay: ${typeof(responsePayment.error) || "ERRO AO DEFINIR"} `, userData )
            setIsSendingOrder(false);
            errorToaster (responsePayment.message_br || responsePayment.message );
            setLoading(false);
            return;
          } else {
            debugAlert(`[DEBUG] - responsePayment.error : NO `, userData )
          }

          // Check if QRCode was returned
          if ( newPayload.payment.type === "pix" ) {
            // Return to Pix
            if (DEBUG) console.log("RE-PAY : PIX QRcode", responsePayment?.payment_qrcode || "Não deu certo");
            if (DEBUG) console.log("RE-PAY : PIX QRcode Text", responsePayment?.payment_qrcode_txt || "Não deu certo");

            // Set QRCode base64
            setPaymentQRCode(responsePayment?.payment_qrcode);
            // Set QRCode text
            setPaymentQRCodeTxt(responsePayment?.payment_qrcode_txt);

            debugAlert(`[DEBUG] - SHOPCARTCLEAR() ON REPAY : AT PIX`, userData )
            // clears the shopcart on PIX repay
            shopcartClean()
            // Loading off
            setLoading(false);
            // 
            setIsSendingOrder(false);
          } else {
            debugAlert(`[DEBUG] - RE-PAY : NOT PIX`, userData )
          }

          debugAlert(`[DEBUG] - responsePayment.status : ${responsePayment.status} `, userData )

          if( responsePayment.status === 1 ) {
            debugAlert(`[DEBUG] - Entrando no success de re-pay, chamando função sendToQueue"} `, userData )
            // On payment retrial success get the order number, payload and go to another page
            sendToQueue();
          } else {
            debugAlert(`[DEBUG] - responsePayment.status NOT 1 `, userData )
          }
        })
      }
      
    } catch (err) {
      setIsSendingOrder(false);
      errorToaster( err?.response?.data?.message_br || err?.response?.data?.message||  "Erro ao gerar pedido. Tente novamente e se o problema persistir, entre em contato com noss equipe de suporte.");
      setLoading(false);
    }
  }

  // Function to pass the order oncer genarated to searching driver at button click
  const handleGoToDriverSearch = (response) => {

    
    if(DEBUG) console.log("DADOS DE PAGAMENTO:", JSON.stringify(response))
    if(DEBUG) console.log("STATUS DA ORDER:", response.financial.payment.status)
    // if(DEBUG)warningToaster("status da order no handle search driver é", response.financial.payment.status )
    // if order status is okay, then goes to driver-search
    if (response.financial.payment.status === 1) {
      debugAlert(`[DEBUG] - Tipo do status financeiro em pay-linear: ${typeof(response.financial.payment.status) || "ERRO AO DEFINIR"} `, userData )
      
      // MP
      MP("Payment : Driver Search", { "success": "true" } );  

      // Making sure that payment order was successfully created
      sendToQueue();      
    } else {
      // Order created but payment failed
      setIsSendingOrder(false);
      if( paymentType === "debit" || paymentType === "credit" ){
        // Credit or Debit payment error

        // MP
        MP("Payment : Driver Search", { "success": "false", "type" : paymentType } );  

        errorToaster("Erro ao realizar pagamento. Verifique os dados de pagamento informados e tente novamente. Se o problema persistir entre em contato com a administradora de seu cartão para liberação.");
        setOrderPaymentHasFailed(true);      
      } else {
        // Pix payment

        // MP
        MP("Payment : Driver Search", { "success": "true", "type" : paymentType } );  

        debugAlert(`[DEBUG] - SHOPCARTCLEAR() FIRST ATTEMPT : TYPE PIX`, userData )
        // clears the shopcart on PIX repay
        shopcartClean()
      }
    }
  }

  useEffect(() => {
    // pushes to driver search page
    if(driverSearchOn) {
      debugAlert(`[DEBUG] -Entrando na rotina de redirecionamento DENTRO do useEffect`, userData )
      history.push("/encontre-motorista");
    }
    // Tour controller
    if (localStorage.hasOwnProperty("pagesTourSeen")){
      if(!JSON.parse(localStorage.getItem("pagesTourSeen")).order_contact) {
        tour.start()
      }
    }else {
      tour.start()
    }

    // setLoading(true);

    // Auth API
    const auth = new Auth();

    // Exec request
    auth.me()
      .then((response) => {
        // getting information from end point        
        setUserData(response)
        // Setter State
        if (!toggle) {
          setNameFrom(response.name)
          setCpfFrom(response.cpf)
          setEmailFrom(response.email)
          setCellphoneFrom(response.cellphone)
        }
        if (!toggle1) {
          setNameTo(response.name)
          setCpfTo(response.cpf)
          setEmailTo(response.email)
          setCellphoneTo(response.cellphone)
        }

        if ( localStorage.hasOwnProperty("pricef") ) {
          const pricef = JSON.parse(localStorage.getItem("pricef"));
          setPrice(pricef);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.warn("Error", error);
        setLoading(false)
      });

  }, [toggle, toggle1, driverSearchOn])

  // Top page
  useEffect(() => {
      try { window.scrollTo(0, 0); } catch (err) {}
  }, []);

  // Function to send the order to the queue
  if( driverSearchOn ) {
    // setLoading(true);
    // Passing order id
    debugAlert(`[DEBUG] -Entrando na rotina de redirecionamento FORA do useEffect`, userData )

    const orderId = JSON.parse(localStorage.getItem("lastestOrderNumber"));
    // Redir
    // window.location = "/encontre-motorista";
    history.push("/encontre-motorista");
    //return <Redirect to="/encontre-motorista"  />
  }

  return (
    <div className="container-principal-OrderContact">
      <NavBar />
      {/* Modal, url and function to window.open function */}
      {modalOpen &&
        <ModalPayment
          setOpenModal={setModalOpen}
          url={apiResponse.financial?.payment?.url}
        />}
      <ToastContainer />
      <div className="container">
        <ButtonVoltar onClick={handleFinishTour} to={links.orderResume} />
        <div className="margin-order-contact">
          <div className="container-central-orderContact">
            <div>
              <Form className="form-local-coleta-orderContact  tour-order-contact-from ">
                <h2>Quem estará no local de coleta ?</h2>
                <div className="toggle-coleta-orderContact tour-order-contact-switch-autocomplete">
                  <div>
                    <Switch
                      selected={toggle}
                      toggleSelected={() => {
                        setToggle(!toggle);
                        handleDeleteDataToggle()
                        handleResetInputError()
                        
                      }}
                    />
                  </div>
                  <div>
                    <h4>Eu mesmo estarei no local de coleta.</h4>
                  </div>
                </div>

                <InputForm
                  autoComplete="on"
                  errorInput={errorInput.nameFrom}
                  style={{ height: "50px", width: "464px" }}
                  name="name"
                  type="name"
                  placeholder="Nome completo"
                  value={nameFrom}
                  onChange={(e) => { 
                    setErrorInput({...errorInput, nameFrom: false })
                    setNameFrom(e.target.value)
                   }}
                />
                <InputMask
                  errorInput={errorInput.cpfFrom}
                  style={{ height: "50px", width: "464px" }}
                  name="cpf"
                  mask="999.999.999-99"
                  maskChar=" "
                  type="cpf"
                  placeholder="CPF"
                  value={cpfFrom}
                  onChange={(e) => {
                     setCpfFrom(e.target.value)
                     setErrorInput({...errorInput, cpfFrom: false })
                   }}
                />

                <InputMask
                  errorInput={errorInput.cellphoneFrom}
                  style={{ height: "50px", width: "464px" }}
                  type="phone"
                  name="phone"
                  mask="(99) 99999-9999"
                  maskChar=" "
                  placeholder="Celular "
                  value={cellphoneFrom}

                  onChange={(e) => { 
                    setCellphoneFrom(e.target.value)
                    setErrorInput({...errorInput, cellphoneFrom: false })

                   }}

                />
                <InputForm
                  errorInput={errorInput.emailFrom}
                  style={{ height: "50px", width: "464px" }}
                  name="email"
                  type="email"
                  placeholder="E-mail"
                  value={emailFrom}

                  onChange={(e) => { 
                    setEmailFrom(e.target.value)
                    setErrorInput({...errorInput, emailFrom: false })
                   }}

                />
              </Form>
              <Form className="form-local-entrega-orderContact  tour-order-contact-to">
                <h2>Quem estará no local de entrega ?</h2>
                <div className="toggle-coleta-orderContact">
                  <div>
                    <Switch
                      selected={toggle1}
                      toggleSelected={() => {
                        setToggle1(!toggle1);
                        handleDeleteDataToggle1()
                        handleResetInputError()
                      }}
                    />
                  </div>
                  <div>
                    <h4>Eu mesmo estarei no local de entrega.</h4>
                  </div>
                </div>

                <InputForm
                  style={{ height: "50px", width: "464px" }}
                  errorInput={errorInput.nameTo}
                  name="name"
                  type="name"
                  placeholder="Nome completo"
                  value={nameTo}
                  onChange={(e) => { 
                    setNameTo(e.target.value)
                    setErrorInput({...errorInput, nameTo: false })
                   }}
                />
                <InputMask
                  errorInput={errorInput.cpfTo}
                  style={{ height: "50px", width: "464px" }}
                  name="cpf"
                  mask="999.999.999-99"
                  maskChar=" "
                  type="cpf"
                  placeholder="CPF"
                  value={cpfTo}
                  onChange={(e) => {
                     setCpfTo(e.target.value) 
                     setErrorInput({...errorInput, cpfTo: false })
                    }}
                />

                <InputMask
                  errorInput={errorInput.cellphoneTo}
                  style={{ height: "50px", width: "464px" }}
                  type="phone"
                  name="phone"
                  mask="(99) 99999-9999"
                  maskChar=" "
                  placeholder="Celular "
                  value={cellphoneTo}

                  onChange={(e) => { 
                    setCellphoneTo(e.target.value)
                    setErrorInput({...errorInput, cellphoneTo: false })

                  }}
                />
                <InputForm
                  errorInput={errorInput.emailTo}
                  style={{ height: "50px", width: "464px" }}
                  name="email"
                  type="email"
                  placeholder="E-mail"
                  value={emailTo}

                  onChange={(e) => { 
                    setEmailTo(e.target.value)
                    setErrorInput({...errorInput, emailTo: false })
                   }}
                />
              </Form>
            </div>
            <div className="divisão-orderContact"></div>
            <div>
              <div className="vlr-entrega-orderContact  tour-order-contact-price">
                <div>
                  <h1>Valor</h1>
                </div>
                <div className="vlr-entrega-orderContact-price ">
                  <div>
                    {price}
                  </div>
                </div>
              </div>

              <div style={{ margin: "20px 0 20px 0" }}>
                { paymentType !== "pix" ?
                  <Cards
                    cvc={cvc}
                    expiry={expiry}
                    focused={focus}
                    name={name}
                    number={number}
                    locale={{ valid: "Válido até" }}
                    placeholders={
                      { name: "Nome do titular" }
                    }
                  />
                  :
                  <div className="payment-qrcode">
                    { paymentQRCode || paymentQRCodeTxt ?
                    <div className="qrcode-image">
                      <p>
                        Realize o pagamento via PIX copia e cola
                      </p>
                      { paymentQRCodeTxt && <div className="payment-text"><input type="text" value={paymentQRCodeTxt} readOnly onClick={copyTxt}/></div> }
                      { paymentQRCodeTxt && paymentQRCode && <div>ou através do QRcode</div> }
                      { paymentQRCode && <img src={`data:image/jpeg;base64,${paymentQRCode}`} alt="PIX QRCode"/> }
                    </div>
                    :
                    <p>
                      Clique em finalizar e aguarde os dados de pagamento.
                    </p>
                    }
                  </div>
                }
              </div>
              { !( paymentQRCode || paymentQRCodeTxt ) ?
              <Form className="formOrderContact tour-order-contact-payment-fields">
                <select
                  className="tour-order-contact-payment-type"
                  value={paymentType}
                  onChange={(e) => { setPaymentType(e.target.value) }}
                >
                  <option value="pix">PIX</option>
                  <option value="credit">Crédito</option>
                  <option value="debit">Débito</option>
                  {/* <option value="boleto">Boleto</option>
                  <option value="transfer">Transferência Bancária</option> */}
                </select>
                { paymentType !== "pix" && 
                  <>
                    <InputMask
                      autoComplete="on"
                      name="number"
                      type="tel"
                      value={number}
                      placeholder="Número do cartão"
                      onChange={(e) => setNumber(e.target.value)}
                      onFocus={(e) => setFocus(e.target.name)}
                      maskChar=""
                      mask="9999 9999 9999 9999"
                      pattern="[0-9]*"
                    />
                    <InputMask
                      autoComplete="on"
                      style={{ height: "50px" }}
                      name="name"
                      type="text"
                      value={name}
                      placeholder="Nome do titular"
                      onChange={(e) => setName(e.target.value)}
                      onFocus={(e) => setFocus(e.target.name)}
                    />
                    <div className="input-date-cod-orderContact">
                      <InputMask
                        autoComplete="on"
                        name="expiry"
                        type="text"
                        value={expiry}
                        placeholder="Mês/Ano"
                        // mask="99/9999"
                        // maskChar="_"
                        maxLength={7}
                        onChange={(e) => handleValidateExpiryInput(e, setExpiry)}
                      />
                      <input
                        autoComplete="on"
                        type="number"
                        placeholder="Cód. segurança"
                        name="cvc"
                        id="cvc"
                        value={cvc}
                        maxLength={3}
                        //Preventing the cvc input to display letters
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          setCvc(e.target.value) 
                        }}
                        onFocus={(e) => { setFocus(e.target.name) }}
                      />
                    </div>
                  </>
                } 
                { /*
                <div className="switch-orderContact">
                  <Switch
                    selected={salvar}
                    toggleSelected={() => {
                      setSalvar(!salvar);
                    }}
                  />
                  <h4> Salvar dados para pagamentos futuros</h4>
                </div>
                */ }
              </Form>
              : <></>}
              {/* <div className="cupon-orderContact">
                <div>
                  <input
                    placeholder="Inserir código"
                    value={ticket}
                    onChange={(e) => setTicket(e.target.value)}
                  />
                </div>
                <div style={{ width: "10px" }}></div>
                <div>
                  <ButtonPadrao
                    img={cupom}
                    type="submit"
                  >
                    Aplicar cupom
                  </ButtonPadrao>
                </div>
              </div> */}
            </div>           
          </div>
          <div className="button-chat">
            <p>Está com dificuldades ?</p>
            <HelpButton />
          </div>             
        </div>
      </div>
      <div className="container-inferior-cinza">
        <div className="container">
          <div className="margin-order-contact">
            <div className="posicionamento-inferior-orderContact">
              <div>
                <img alt="transporteSegurado" src={transporteSegurado} />
              </div>
              <div className="btn-finalizar-orderContact tour-order-contact-payment-finish">
                { paymentQRCode ? 
                
                <Link to={{
                  pathname: links.trackingmap,
                  state: { item: { order : { id : JSON.parse(localStorage.getItem("lastestOrderNumber") || "0") } } }
                }} style={{ textDecoration: "none" }}
                  
                  >
                  <ButtonPadrao img={caminhao}>Acompanhar</ButtonPadrao>
                </Link>
                :
                <ButtonPadrao className={isSendingOrder?"btn-finalizar-orderContact-wait":""} onClick={isSendingOrder?_handleSaveDataStorageTry:_handleSaveDataStorage} img={isSendingOrder?wait:cartao}>{isSendingOrder?"Aguarde...":"Finalizar"}</ButtonPadrao>
                }
                <div>
                  <img alt="ambienteSeguro" src={ambienteSeguro} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}


export default withRouter(OrderContact)