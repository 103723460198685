// Data analysis
import { MP } from "../../components/MixPanel";

// Css
import "./style.css";

// Local components
import NavBar from "../../components/NavBar";
import ButtonVoltar from "../../components/ButtonVoltar";
import Webcontent from "../../components/Webcontent";

// Routes
import links from "../../config/Links";

export default function FAQ() {

  // MP
  MP("FAQ"); 
    
  return (
    <div className="container-principal">
      <NavBar />
      <div className="container">
      <ButtonVoltar to={links.main}/>
        <div className="container-LegalInfoPage">
          <h1>Dúvidas Frequentes</h1>
          <Webcontent url="https://ecarreto.com.br/faq/628/duvidas-frequentes-para-solicitacao-de-carretos-entregas-e-fretes-rapidos.html" title="Informações Legais"/>
        </div>
      </div>
    </div>
  );
}
